var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { appColors } from '@/config/constants';
import { updatePhoto } from '@/services/api/photoAPI';
import { Box, ButtonGroup, IconButton, Input, Text } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { toast } from '@frontend/domain/ToastContainer';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MdClear, MdDone, MdModeEditOutline } from 'react-icons/md';
export const PhotoDate = ({ photo, setInvalidDate, setSelectedDate, }) => {
    const [photoDate, setPhotoDate] = useState(undefined);
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState('');
    moment.locale('en');
    const months = moment.months();
    useEffect(() => {
        if (photo) {
            const date = `${photo === null || photo === void 0 ? void 0 : photo.date}T00:00:00`;
            const newDate = new Date(date);
            setPhotoDate(newDate);
            setSelectedDate && setSelectedDate(newDate);
        }
        else {
            const today = new Date();
            setPhotoDate(today);
            setSelectedDate && setSelectedDate(today);
        }
    }, [photo]);
    const handleDateChange = (event) => {
        const inputDate = event.target.value;
        const newDate = new Date(`${inputDate}T12:00:00Z`);
        const currentDate = moment();
        const inputMoment = moment(inputDate, 'YYYY-MM-DD', true);
        if (inputMoment.isAfter(currentDate) ||
            inputMoment.isBefore(currentDate.subtract(200, 'years')) ||
            !inputMoment.isValid()) {
            if (setInvalidDate) {
                setInvalidDate(true);
            }
            setError('Please enter a date between now and 200 years in the past');
        }
        else {
            if (setInvalidDate) {
                setInvalidDate(false);
            }
            setError('');
        }
        if (inputDate && inputMoment.isValid()) {
            setPhotoDate(newDate);
            setSelectedDate && setSelectedDate(newDate);
        }
        else {
            setPhotoDate(undefined);
            setSelectedDate && setSelectedDate(undefined);
        }
    };
    const renderUploadPhotoDate = () => {
        return (_jsxs(Box, Object.assign({ mb: '25px' }, { children: [_jsx(Text, Object.assign({ color: appColors.PRIM_BLUE, pl: '15px' }, { children: "Date" })), _jsx(Input, { "data-testid": 'photo-date-input', type: 'date', h: '36px', w: '239px', pl: '15px', pr: '15px', borderRadius: '50px', fontSize: '14px', value: photoDate && photoDate.toISOString().split('T')[0], onChange: handleDateChange, variant: variants.fcaBaseStyle }), error && (_jsx(Text, Object.assign({ color: appColors.DELETE_ERROR, fontSize: '12px' }, { children: error })))] })));
    };
    const handleCancel = () => {
        const date = `${photo === null || photo === void 0 ? void 0 : photo.date}T12:00:00Z`;
        const newDate = new Date(date);
        setPhotoDate(newDate);
        setError('');
    };
    const updateDate = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (photo) {
            try {
                yield updatePhoto((_a = photo.id) !== null && _a !== void 0 ? _a : '', {
                    date: photoDate,
                });
                toast({
                    title: 'date updated',
                    status: 'success',
                    isClosable: true,
                });
            }
            catch (_b) {
                toast({
                    title: 'Error updating date',
                    description: 'Please check that all fields are entered and try again.',
                    status: 'error',
                    isClosable: true,
                });
            }
        }
    });
    const renderEditPhotoDate = () => {
        return (_jsxs(Box, Object.assign({ mt: '5px' }, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center' }, { children: [photoDate && !editMode && (_jsxs(Text, Object.assign({ fontSize: '14px' }, { children: [_jsx("b", { children: "Photo date:" }), ' ', `${photoDate.getDate()} ${months[photoDate.getMonth()]} ${photoDate.getFullYear()}`] }))), !editMode && (_jsx(IconButton, { "aria-label": 'edit date', icon: _jsx(MdModeEditOutline, {}), p: 0, size: 'sm', variant: 'ghost', onClick: () => setEditMode(true) }))] })), editMode && (_jsx(Input, { "data-testid": 'photo-date-input', type: 'date', h: '36px', w: '200px', pl: '15px', pr: '15px', borderRadius: '50px', fontSize: '14px', value: photoDate && photoDate.toISOString().split('T')[0], onChange: handleDateChange })), editMode && (_jsxs(ButtonGroup, { children: [_jsx(IconButton, { "aria-label": 'save date', icon: _jsx(MdDone, {}), p: 0, size: 'sm', variant: 'ghost', onClick: () => {
                                updateDate();
                                setEditMode(false);
                            }, isDisabled: error ? true : false }), _jsx(IconButton, { "aria-label": 'cancel date edit', icon: _jsx(MdClear, {}), p: 0, size: 'sm', variant: 'ghost', onClick: () => {
                                handleCancel();
                                setEditMode(false);
                            } })] })), error && editMode && (_jsx(Text, Object.assign({ color: appColors.DELETE_ERROR, fontSize: '12px' }, { children: error })))] })));
    };
    return _jsx(_Fragment, { children: !photo ? renderUploadPhotoDate() : renderEditPhotoDate() });
};
