var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, HStack, Link, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, useBreakpointValue, VStack, } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { SelectInput } from '@frontend/design-system/components/DynamicInput/SelectInput';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { TextInput } from '@frontend/design-system/components/Inputs/TextInput';
import { Tabs, } from '@frontend/design-system/components/Tabs/Tabs';
import { validateLocationFields } from '@frontend/domain/formValidations/LocationValidation';
import { PropertySizes, } from '@frontend/domain/models/Facility/FacilityInterfaces';
import { AddressAutofillInput } from '@frontend/shared/src/components/Location/AddressAutofillInput';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { handlePhotoUploadErrorMessage } from '../../../utils/photoFileUtils';
import { validateYearRange } from '../../Main/Sidebar/DetailsSidebar/utility/Validations';
import { FacilityAdditions } from './FacilityAdditions';
import { FacilityStudents } from './FacilityStudents';
import { PhotoUploadInput } from './PhotoUploadInput';
import BodyRegular from '@frontend/design-system/components/Typography/Body/Body';
import BodyStrong from '@frontend/design-system/components/Typography/BodyStrong/BodyStrong';
const ACCEPTED_FILE_TYPES = ['.jpg', '.jpeg', '.png', '.gif', '.svg'];
const FACILITY_NAME_KEY = 'name';
const ADDRESS_KEY = 'address';
const OWNERSHIP_TYPE_KEY = 'ownershipType';
const PRIMARY_TYPE_KEY = 'primaryFacilityType';
const SECONDARY_TYPE_KEY = 'secondaryFacilityType';
const TERTIARY_TYPE_KEY = 'tertiaryFacilityType';
const YEAR_BUILT = 'yearBuilt';
const PROPERTY_SIZE = 'propertySize';
const PROPERTY_TYPE = 'propertyType';
const SITE_KEY = 'siteName';
const PHOTO = 'photo';
export const FacilityFormInputs = ({ facilityTemplate, facilityForm, validateFacilityName, facility, onSubmitFacility, isSaveDisabled, imageName, imageRef, handleModalClose, }) => {
    var _a, _b, _c, _d, _e, _f;
    const { control, setValue, handleSubmit, trigger } = facilityForm;
    const inputRef = useRef(null);
    const minYear = 1700;
    const maxYear = new Date().getFullYear() + 2;
    const [currentFacilityPropertySizeType, setCurrentFacilityPropertySizeType,] = useState(undefined);
    useEffect(() => {
        setCurrentFacilityPropertySizeType(facility === null || facility === void 0 ? void 0 : facility.propertySize.type);
    }, [facility]);
    const labelSize = (_a = useBreakpointValue({
        base: '10px',
        lg: '14px',
    })) !== null && _a !== void 0 ? _a : '10px';
    const inputSize = (_b = useBreakpointValue({
        base: '25px',
        lg: '36px',
    })) !== null && _b !== void 0 ? _b : '25px';
    const buttonSize = (_c = useBreakpointValue({
        base: 'small',
        lg: 'medium',
    })) !== null && _c !== void 0 ? _c : 'small';
    const styleConfig = {
        label: {
            fontSize: labelSize,
        },
        input: {
            height: inputSize,
        },
    };
    const siteName = useWatch({ name: SITE_KEY, control });
    const primaryFacilityType = useWatch({
        name: PRIMARY_TYPE_KEY,
        control,
    });
    const secondaryFacilityType = useWatch({
        name: SECONDARY_TYPE_KEY,
        control,
    });
    const enrollmentYears = useWatch({
        name: 'enrollmentYears',
        control,
    });
    const hasEnrollment = (_e = (_d = facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.primaryFacilityTypes.find((type) => type.identity === primaryFacilityType)) === null || _d === void 0 ? void 0 : _d.hasEnrollment) !== null && _e !== void 0 ? _e : false;
    const getPropertyType = (facilityType) => {
        var _a;
        return (((_a = facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.primaryFacilityTypes.find((type) => type.identity === facilityType)) === null || _a === void 0 ? void 0 : _a.sizeType) || PropertySizes.SQFT);
    };
    const normalize = (str) => {
        return !str ? '' : str.toLowerCase().replace(/\s+/g, '');
    };
    const getFacilityTypeOptions = (previousFacilityType, facilityTypes = []) => {
        return facilityTypes
            .filter((type) => type.parentFacilityTypeId === previousFacilityType)
            .map((option) => ({
            label: option.name,
            value: option.identity,
        }));
    };
    const primaryFacilityTypeOptions = ((facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.primaryFacilityTypes) || []).map((option) => ({
        label: option.name,
        value: option.identity,
    }));
    const secondaryFacilityTypeOptions = getFacilityTypeOptions(primaryFacilityType, facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.secondaryFacilityTypes);
    const tertiaryFacilityTypeOptions = getFacilityTypeOptions(secondaryFacilityType, facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.tertiaryFacilityTypes);
    const initialTab = {
        column: 'Additions',
        nestedComponent: _jsx(FacilityAdditions, { facilityForm: facilityForm }),
        customStyles: {
            width: '100%',
        },
    };
    const [facilityTabs, setFacilityTabs] = useState([
        initialTab,
    ]);
    useEffect(() => {
        if (hasEnrollment) {
            setFacilityTabs([
                {
                    column: (_jsxs(BodyRegular, { children: ["Enrollment by Grade", ' ', _jsx(BodyStrong, Object.assign({ color: 'ui.danger', style: { display: 'inline' } }, { children: "*" }))] })),
                    nestedComponent: (_jsx(FacilityStudents, { facilityForm: facilityForm, facilityTemplate: facilityTemplate, validateEnrollment: validateEnrollment })),
                    icon: (_jsxs(Popover, Object.assign({ trigger: 'hover', placement: 'top' }, { children: [_jsx(PopoverTrigger, { children: _jsx("span", { children: _jsx(AiOutlineInfoCircle, { fontSize: '15px', cursor: 'pointer' }) }) }), _jsxs(PopoverContent, { children: [_jsx(PopoverArrow, {}), _jsx(PopoverBody, { children: _jsx(Link, Object.assign({ href: 'https://nces.ed.gov/ccd/schoolsearch/', isExternal: true, color: 'blue.500' }, { children: "https://nces.ed.gov/ccd/schoolsearch/" })) })] })] }))),
                    customStyles: {
                        width: '100%',
                    },
                },
                initialTab,
            ]);
        }
        else {
            setFacilityTabs([initialTab]);
        }
    }, [primaryFacilityType]);
    useEffect(() => {
        const enrollmentYears = facilityForm.getValues('enrollmentYears');
        if (hasEnrollment) {
            if ((enrollmentYears === null || enrollmentYears === void 0 ? void 0 : enrollmentYears.length) === 0 ||
                !validateEnrollment(enrollmentYears)) {
                facilityForm.setError('enrollmentYears', {
                    message: 'Facility should have at least one enrollment for each year',
                });
            }
            else {
                facilityForm.clearErrors('enrollmentYears');
            }
        }
        else {
            facilityForm.clearErrors('enrollmentYears');
        }
    }, [hasEnrollment, facilityForm, enrollmentYears]);
    const [sites, setSites] = useState([]);
    useEffect(() => {
        setSites((facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.siteNames) || []);
    }, [facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.siteNames]);
    const validateName = (value, array) => {
        if (!value || value.trim() === '') {
            return false;
        }
        const normalizedValue = normalize(value);
        const normalizedArray = array.map((item) => normalize(item.name));
        const isInvalid = normalizedArray.some((item) => item === normalizedValue);
        return !isInvalid;
    };
    const validateNameInput = (value) => {
        const optionsNames = sites.map((option) => {
            return { name: option };
        });
        return validateName(value, optionsNames);
    };
    const validateEnrollment = (enrollmentYears) => {
        var _a;
        return ((_a = enrollmentYears === null || enrollmentYears === void 0 ? void 0 : enrollmentYears.every((enrollment) => {
            const { schoolYear, gradeEnrollment } = enrollment;
            if (!schoolYear)
                return false;
            const hasStudents = gradeEnrollment === null || gradeEnrollment === void 0 ? void 0 : gradeEnrollment.some((item) => item.count !== '');
            return hasStudents !== null && hasStudents !== void 0 ? hasStudents : false;
        })) !== null && _a !== void 0 ? _a : false);
    };
    const onAutofillReset = () => __awaiter(void 0, void 0, void 0, function* () {
        facilityForm.setValue('address', {
            address1: '',
            address2: '',
            city: '',
            stateOrProvince: '',
            postalCode: '',
        });
        yield trigger();
    });
    return (_jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmitFacility), id: 'facility_form', "data-testid": 'facility-form' }, { children: _jsxs(VStack, Object.assign({ w: '100%', align: 'start', gap: 10 }, { children: [_jsxs(Flex, Object.assign({ width: '100%', gap: 5, flexDirection: { base: 'column', lg: 'row' } }, { children: [_jsx(Form.Column, Object.assign({ stackProps: { flex: 1 } }, { children: _jsx(Controller, { name: PHOTO, control: control, rules: {
                                    validate: () => {
                                        var _a, _b;
                                        if ((_a = imageRef.current) === null || _a === void 0 ? void 0 : _a.files) {
                                            return handlePhotoUploadErrorMessage((_b = imageRef.current) === null || _b === void 0 ? void 0 : _b.files[0], ACCEPTED_FILE_TYPES);
                                        }
                                    },
                                }, render: ({ field, fieldState: { error } }) => (_jsx(PhotoUploadInput, { imageUrl: field.value, imageName: imageName, imageRef: imageRef, imageError: error === null || error === void 0 ? void 0 : error.message, onImageChange: field.onChange, acceptedFileTypes: ACCEPTED_FILE_TYPES.join(', ') })) }) })), _jsxs(Form.Column, Object.assign({ stackProps: { flex: 2 } }, { children: [_jsx(Controller, { name: FACILITY_NAME_KEY, control: control, rules: {
                                        required: true,
                                        validate: (e) => validateFacilityName(e, facility === null || facility === void 0 ? void 0 : facility.identity, siteName),
                                    }, render: ({ field, fieldState: { error } }) => {
                                        return (_jsx(TextInput, { label: 'Name', editMode: true, data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, constraints: {
                                                maxLength: 50,
                                                required: true,
                                            }, customStyles: styleConfig }));
                                    } }), _jsx(Controller, { name: SITE_KEY, control: control, rules: {
                                        required: true,
                                        deps: [FACILITY_NAME_KEY],
                                    }, render: ({ field }) => {
                                        return (_jsx(Form.CreatableDropdown, { customStyles: styleConfig, label: 'Site name', selectFrom: sites
                                                .filter((site) => normalize(field.value) !==
                                                normalize(site))
                                                .map((site) => ({
                                                label: site,
                                                value: normalize(site),
                                            })), placeholder: 'Site Name', value: field.value
                                                ? {
                                                    value: field.value,
                                                    label: field.value,
                                                }
                                                : undefined, 
                                            // handle change events on the select
                                            onChange: (e) => {
                                                field.onChange(e === null || e === void 0 ? void 0 : e.label);
                                            }, onCreate: (e) => {
                                                setSites((prev) => [...prev, e]);
                                                field.onChange(e);
                                            }, isValidNewOption: (e) => validateNameInput(e), maxLength: 50, constraints: { required: true }, filterOption: (option, inputValue) => {
                                                return normalize(option.label).includes(normalize(inputValue));
                                            }, 
                                            // TODO: figure out styling with this, height is not working
                                            chakraStyles: {
                                                control: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { minH: '0px', height: inputSize, fontSize: '14px' })),
                                                inputContainer: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { minH: '0px', height: inputSize, fontSize: '14px', display: 'flex', alignItems: 'center' })),
                                                menuList: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { borderRadius: '0px 0px 5px 5px' })),
                                                dropdownIndicator: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { height: inputSize, background: 'none', borderLeft: 'none' })),
                                                indicatorSeparator: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { height: inputSize, borderLeft: 'none' })),
                                                option: (baseStyles, state) => (Object.assign(Object.assign({}, baseStyles), { background: state.isFocused
                                                        ? '#FEF3EA'
                                                        : 'none' })),
                                            } }));
                                    } }), _jsx(Controller, { name: ADDRESS_KEY, control: control, rules: {
                                        required: true,
                                        validate: validateLocationFields,
                                    }, render: ({ field, fieldState: { error } }) => (_jsx(AddressAutofillInput, { label: 'Address', errorMessage: error === null || error === void 0 ? void 0 : error.message, onPlaceSelect: (place) => {
                                            field.onChange(place);
                                        }, onAutofillReset: onAutofillReset, constraints: { required: true }, location: field.value, inputRef: inputRef, customStyles: styleConfig })) }), _jsx(Controller, { name: OWNERSHIP_TYPE_KEY, control: control, render: ({ field }) => (_jsx(SelectInput, { label: 'Ownership status', editable: true, data: field.value, choices: ((facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.ownershipTypes) || []).map((ownershipType) => ({
                                            label: ownershipType,
                                            value: ownershipType,
                                        })), placeholder: 'Select', onInputChange: field.onChange, customStyles: styleConfig })) }), _jsx(Controller, { name: YEAR_BUILT, control: control, rules: {
                                        required: true,
                                        validate: (year) => {
                                            return validateYearRange(Number(year), minYear, maxYear);
                                        },
                                    }, render: ({ field, fieldState: { error } }) => (_jsx(TextInput, { label: 'Construction year', editMode: true, data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: (e) => {
                                            if (/^\d*$/.test(e)) {
                                                field.onChange(e);
                                            }
                                        }, onBlurChange: () => {
                                            if (field.value) {
                                                const adjustedValue = Math.max(minYear, Math.min(maxYear, Number(field.value)));
                                                field.onChange(adjustedValue);
                                            }
                                        }, constraints: { required: true }, customStyles: styleConfig })) })] })), _jsxs(Form.Column, Object.assign({ stackProps: { flex: 2 } }, { children: [_jsx(Controller, { name: PRIMARY_TYPE_KEY, control: control, rules: { required: true }, render: ({ field }) => (_jsx(SelectInput, { label: 'Facility type', editable: true, data: field.value, choices: primaryFacilityTypeOptions, placeholder: 'Select', onInputChange: (e) => {
                                            const value = parseInt(e);
                                            field.onChange(value);
                                            const propertyType = getPropertyType(value);
                                            setValue(PROPERTY_TYPE, propertyType);
                                            setValue(SECONDARY_TYPE_KEY, undefined);
                                            setValue(TERTIARY_TYPE_KEY, undefined);
                                            if (currentFacilityPropertySizeType &&
                                                propertyType !==
                                                    currentFacilityPropertySizeType) {
                                                setValue(PROPERTY_SIZE, '');
                                            }
                                        }, required: true, customStyles: styleConfig })) }), primaryFacilityType &&
                                    secondaryFacilityTypeOptions.length > 0 && (_jsx(Controller, { name: SECONDARY_TYPE_KEY, control: control, rules: { required: true }, render: ({ field }) => (_jsx(SelectInput, { label: 'Secondary Facility Type', editable: true, data: field.value, choices: secondaryFacilityTypeOptions, placeholder: 'Select', onInputChange: (e) => {
                                            const value = parseInt(e);
                                            field.onChange(value);
                                            setValue(TERTIARY_TYPE_KEY, undefined);
                                        }, required: true, customStyles: styleConfig })) })), primaryFacilityType &&
                                    secondaryFacilityType &&
                                    tertiaryFacilityTypeOptions.length > 0 && (_jsx(Controller, { name: TERTIARY_TYPE_KEY, control: control, rules: { required: true }, render: ({ field }) => (_jsx(SelectInput, { label: 'Tertiary Facility Type', editable: true, data: field.value, choices: tertiaryFacilityTypeOptions, placeholder: 'Select', onInputChange: (e) => {
                                            const value = parseInt(e);
                                            field.onChange(value);
                                        }, required: true, customStyles: styleConfig })) })), _jsx(Controller, { name: PROPERTY_SIZE, control: control, rules: {
                                        required: true,
                                    }, render: ({ field, fieldState: { error } }) => {
                                        const propertyType = getPropertyType(primaryFacilityType);
                                        const getPropertySizeLabel = (type) => {
                                            switch (type) {
                                                case PropertySizes.SQFT:
                                                    return 'Original SQFT';
                                                case PropertySizes.KEYS:
                                                    return '# of Keys';
                                                case PropertySizes.UNITS:
                                                    return '# of Units';
                                                default:
                                                    return 'Original SQFT';
                                            }
                                        };
                                        return (_jsx(TextInput, { label: getPropertySizeLabel(propertyType), editMode: true, data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: (e) => {
                                                if (/^\d*$/.test(e)) {
                                                    field.onChange(e);
                                                }
                                            }, constraints: { required: true }, customStyles: styleConfig }));
                                    } })] }))] })), _jsxs(Box, Object.assign({ w: '100%' }, { children: [_jsx(Tabs, { tabs: facilityTabs, isFitted: false }), facilityForm.formState.isSubmitted &&
                            facilityForm.formState.errors.enrollmentYears && (_jsx(BodyRegular, Object.assign({ color: 'ui.danger', mt: 2 }, { children: (_f = facilityForm.formState.errors
                                .enrollmentYears) === null || _f === void 0 ? void 0 : _f.message })))] })), _jsxs(HStack, Object.assign({ w: '100%', justifyContent: 'space-between' }, { children: [_jsx(Button, { text: 'Cancel', buttonType: 'secondary', size: buttonSize, onClick: handleModalClose }), _jsx(Button, { type: 'submit', text: 'Save', buttonType: 'primary', size: buttonSize, isDisabled: isSaveDisabled })] }))] })) })));
};
