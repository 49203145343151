var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { usePhotoContext } from '@/contexts/Photo/PhotoContext';
import { Box, Center, Circle, Flex, FormLabel, HStack, IconButton, Stack, Textarea, useDisclosure, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useFcaPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { toast } from '@frontend/domain/ToastContainer';
import { Pannellum } from 'pannellum-react';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsStar, BsStarFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { MdOpenInFull, MdSend } from 'react-icons/md';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { appColors } from '../../../../../config/constants';
import { toBlobStoreContainerUrl } from '../../../../../utils/photoFileUtils';
import { DeletePhotoModal } from './DeletePhotoModal';
import { FullScreenPhotoViewer } from './FullScreenPhotoViewer';
import { PhotoCommentCard } from './PhotoCommentCard';
import { PhotoDate } from './PhotoDate';
import { PhotoTags } from './PhotoTags';
export const PhotoViewer = ({ panoramicSelected, onImageLoad, onClosePhotoViewer, }) => {
    var _a;
    const { user: userCurrent } = useCurrentUser();
    const { selectedPhoto, updateFavorite, addCommentToPhoto, addTag, removeTag, getSinglePhoto, } = usePhotoContext();
    const { canEdit } = useFcaPermissions((_a = selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.facility) === null || _a === void 0 ? void 0 : _a.identity);
    const [videoSelected, setVideoSelected] = useState(false);
    const { register: commentsRegister, handleSubmit: commentsHandleSubmit, reset: commentsReset, } = useForm();
    const { register: tagRegister, reset: tagReset } = useForm();
    const commentRegister = commentsRegister(COMMENT_KEY);
    const { isOpen: isOpenPhotoDelete, onOpen: onOpenPhotoDelete, onClose: onClosePhotoDelete, } = useDisclosure();
    const { isOpen: isOpenFullscreen, onOpen: onOpenFullscreen, onClose: onCloseFullscreen, } = useDisclosure();
    useEffect(() => {
        if ((selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.name.slice(-4, selectedPhoto.name.length).toLowerCase()) === '.mov' ||
            (selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.name.slice(-4, selectedPhoto.name.length).toLowerCase()) === '.mp4') {
            setVideoSelected(true);
        }
        else {
            setVideoSelected(false);
        }
    }, [selectedPhoto]);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (userCurrent) {
                yield addCommentToPhoto({
                    content: data.content,
                    userIdentity: userCurrent.identity,
                });
            }
            else {
                throw Error('no current user');
            }
        }
        catch (err) {
            console.error('unable to add comment', err);
            toast({
                title: 'Unable to Add Comment',
                description: 'there was an issue adding your comment',
                status: 'error',
                isClosable: true,
            });
        }
        commentsReset();
    });
    const renderPhotoComments = () => {
        var _a;
        if (selectedPhoto &&
            selectedPhoto.comments &&
            selectedPhoto.comments.length > 0) {
            const photoCommentsSorted = [
                ...((_a = selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.comments) !== null && _a !== void 0 ? _a : []),
            ].sort((a, b) => new Date(a.createDate).getUTCSeconds() -
                new Date(b.createDate).getUTCSeconds());
            return (_jsx(Stack, Object.assign({ spacing: '20px', pb: '10px' }, { children: photoCommentsSorted.map((comment, i) => (_jsx(PhotoCommentCard, { comment: comment }, `${comment.content} ${i}`))) })));
        }
        return _jsx(_Fragment, {});
    };
    const renderPhotoActions = () => {
        return (_jsxs(_Fragment, { children: [_jsx(Circle, Object.assign({ size: '35px', position: 'absolute', top: '2', right: '5', bg: '#00000080', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        if (selectedPhoto) {
                            yield updateFavorite(selectedPhoto);
                            yield getSinglePhoto(selectedPhoto.id);
                        }
                    }), "aria-label": 'favorite photo' }, { children: (selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.favorite) ? (_jsx(BsStarFill, { size: '22px', color: '#FEDB5E' })) : (_jsx(BsStar, { size: '22px', color: '#FEDB5E' })) })), _jsx(Circle, Object.assign({ size: '35px', position: 'absolute', top: '12', right: '5', bg: '#00000080', onClick: onOpenPhotoDelete, "aria-label": 'delete photo' }, { children: _jsx(FaTrash, { size: '22px', color: '#F6862A' }) })), _jsx(Circle, Object.assign({ size: '35px', position: 'absolute', top: '90', right: '5', bg: '#00000080', onClick: onOpenFullscreen, "aria-label": 'Open photo in fullscreen' }, { children: _jsx(MdOpenInFull, { size: '22px', color: '#f0f0f0' }) }))] }));
    };
    const renderViewer = (panoramicSelected) => {
        const compressedPhotoUrl = selectedPhoto && toBlobStoreContainerUrl(selectedPhoto.name);
        if (panoramicSelected) {
            return (_jsxs(Center, Object.assign({ position: 'relative' }, { children: [_jsxs(Box, { children: [_jsx(Pannellum, { width: '80vw', height: '70vh', image: compressedPhotoUrl, pitch: 10, yaw: 180, hfov: 110, autoLoad: true, onLoad: () => { } }), _jsx(PhotoDate, { photo: selectedPhoto })] }), renderPhotoActions()] })));
        }
        else if (videoSelected) {
            return (_jsxs(Center, Object.assign({ position: 'relative' }, { children: [_jsxs(Box, { children: [_jsxs("video", Object.assign({ id: 'video-player', style: { height: '70vh' }, preload: 'auto', "data-setup": '{height: 100%, maxHeight: 50vh, responsive: true}', 
                                // ref={videoRef2}
                                controls: true }, { children: [_jsx("source", { src: compressedPhotoUrl, type: 'video/mp4' }), _jsx("p", { children: "To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video" })] })), _jsx(PhotoDate, { photo: selectedPhoto })] }), renderPhotoActions()] })));
        }
        else {
            return (_jsxs(Center, Object.assign({ position: 'relative' }, { children: [_jsx(TransformWrapper, { children: () => (_jsx(Fragment, { children: _jsxs(Box, { children: [_jsx(TransformComponent, { children: _jsx("img", { onLoad: onImageLoad, style: {
                                                maxHeight: '70vh',
                                                objectFit: 'contain',
                                            }, src: compressedPhotoUrl, alt: selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.name }) }), _jsx(PhotoDate, { photo: selectedPhoto })] }) })) }), renderPhotoActions()] })));
        }
    };
    const handleTagSelect = (event) => __awaiter(void 0, void 0, void 0, function* () {
        const tagIdentity = Number(event.target.value);
        try {
            if (tagIdentity) {
                yield addTag(tagIdentity);
                tagReset({
                    tag: '',
                });
                toast({
                    title: 'Tag added',
                    status: 'success',
                    isClosable: true,
                });
            }
        }
        catch (e) {
            toast({
                title: 'Error adding tag',
                status: 'error',
                isClosable: true,
            });
        }
    });
    const handleTagDelete = (tagToDelete) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!canEdit) {
                throw Error('You do not have permission to remove photo tags.');
            }
            if (selectedPhoto) {
                yield removeTag(tagToDelete);
                toast({
                    title: 'Tag removed',
                    status: 'success',
                    isClosable: true,
                });
            }
            else {
                console.error('no selected photo');
                throw Error('No selected photo to remove.');
            }
        }
        catch (err) {
            toast({
                title: err.message || 'Error removing tag',
                status: 'error',
                isClosable: true,
            });
        }
    });
    return (_jsx(Flex, Object.assign({ justifyContent: 'center' }, { children: _jsxs(Stack, Object.assign({ w: '100%' }, { children: [renderViewer(panoramicSelected), _jsx(Box, Object.assign({ mt: '12px' }, { children: _jsx(PhotoTags, { handleTagSelect: handleTagSelect, handleTagDelete: handleTagDelete, register: tagRegister, tagsToDisplay: selectedPhoto && selectedPhoto.tags, canEdit: canEdit }) })), renderPhotoComments(), navigator.onLine && (_jsxs("form", Object.assign({ onSubmit: commentsHandleSubmit(onSubmit) }, { children: [_jsx(FormLabel, Object.assign({ fontWeight: 600, fontSize: 16, htmlFor: 'comment', variant: variants.fcaBaseStyle }, { children: "Leave a comment" })), _jsxs(HStack, Object.assign({ bg: 'white', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', borderRadius: '2xl', spacing: 0 }, { children: [_jsx(Textarea, Object.assign({}, commentRegister, { placeholder: 'Type your comments here', borderRadius: '2xl', boxSizing: 'border-box', p: '10px', resize: 'none', id: 'comment', variant: variants.fcaBaseStyle })), _jsx(IconButton, { type: 'submit', "aria-label": 'send-comment', h: '100%', borderLeftRadius: 0, variant: 'ghost', icon: _jsx(MdSend, { color: appColors.SEC_ORANGE, style: {
                                            width: '22px',
                                            height: '22px',
                                        } }) })] }))] }))), _jsx(DeletePhotoModal, { isOpen: isOpenPhotoDelete, onClose: onClosePhotoDelete, onClosePhotoViewer: onClosePhotoViewer }), _jsx(FullScreenPhotoViewer, { panoramicSelected: panoramicSelected, onImageLoad: onImageLoad, isOpen: isOpenFullscreen, onClose: onCloseFullscreen })] })) })));
};
const COMMENT_KEY = 'content';
