import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, VStack, } from '@chakra-ui/react';
import React from 'react';
import { MdMoreVert } from 'react-icons/md';
import Button from '../Button/Button';
export const PopoverMenu = ({ menuItems, position = 'auto', }) => {
    return (_jsx(PopoverWrapper, Object.assign({ menuItems: menuItems, position: position }, { children: _jsx(IconButton, { h: 5, minW: 5, size: 'lg', icon: _jsx(MdMoreVert, {}), "aria-label": 'Action Menu', variant: 'ghost', color: 'primary.blue.500' }) })));
};
export const PopoverWrapper = ({ menuItems, position = 'auto', children, }) => {
    return (_jsxs(Popover, Object.assign({ placement: position }, { children: [_jsx(PopoverTrigger, { children: children }), _jsxs(PopoverContent, Object.assign({ width: 'fit-content', border: '1px solid', borderColor: 'secondary.light.300', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)', bg: 'secondary.light.100' }, { children: [_jsx(PopoverArrow, { bg: 'secondary.light.100' }), _jsx(PopoverBody, Object.assign({ width: 'fit-content', p: 2 }, { children: _jsx(VStack, Object.assign({ gap: 2.5, alignItems: 'start' }, { children: menuItems.map((menuItem, index) => {
                                return (_jsx(Button, { buttonType: 'customLink', size: 'medium', onClick: menuItem.onClick, isDisabled: menuItem.isDisabled, text: menuItem.label }, `${menuItem.label}-${index}`));
                            }) })) }))] }))] })));
};
