import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@chakra-ui/react';
import { CheckboxInput } from '@frontend/design-system/components/DynamicInput/CheckboxInput';
import { NumberDynamicInput } from '@frontend/design-system/components/DynamicInput/NumberDynamicInput';
import { useEffect, useState } from 'react';
import { stringToInt } from '../../../../conversions/AssetConversions';
import { variants } from '@frontend/design-system/theme/theme';
export const RoomOccupiedInput = ({ editMode, data, handleInputChange, required = false, errorMessage, }) => {
    const [isChecked, setIsChecked] = useState(false);
    const onCheckBox = (isChecked) => {
        if (!isChecked) {
            handleInputChange(undefined);
        }
        setIsChecked(isChecked);
    };
    useEffect(() => {
        // If there is occupancy data initially, OR we're in edit mode and have
        // checked the box, keep the input field visible
        if ((data && data !== '0') || (isChecked && editMode)) {
            setIsChecked(true);
        }
        else {
            setIsChecked(false);
        }
    }, [data]);
    return (_jsxs(Stack, Object.assign({ w: '100%' }, { children: [_jsx(CheckboxInput, { label: 'Occupied Room?', editMode: editMode, data: isChecked, onInputChange: onCheckBox, required: required, errorMessage: errorMessage, variant: variants.fcaBaseStyle }), isChecked && (_jsx(NumberDynamicInput
            // variant={variants.fcaBaseStyle}
            , { 
                // variant={variants.fcaBaseStyle}
                label: '# of Occupants', editMode: editMode, data: stringToInt(data), errorMessage: errorMessage, onInputChange: handleInputChange, required: required, constraints: { precision: 0, min: 1, max: 100 } }))] })));
};
