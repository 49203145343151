import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue, } from '@chakra-ui/react';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { getFileExtension } from '@frontend/design-system/utils/fileUtils';
import { useFacility } from '@frontend/domain/contexts/Facility/FacilityHook';
import { convertIFacilityToFacilityForm } from '@frontend/domain/models/Facility/FacilityConversions';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FacilityFormInputs } from './FacilityFormInputs';
import { ImportFacilities } from './ImportFacilitiesTab';
export const FacilityModal = ({ facilityIdentity, facilityDisclosure, facilityTemplate, orgIdentity, validateFacilityName, onUpdateFacility, importFacilities, }) => {
    var _a, _b;
    const { fetchFacility, facility, updateFacility, isLoading: isFacilityLoading, } = useFacility({
        facilityIdentity: facilityIdentity,
        shouldFetchOnInit: false,
        onFacilityUpdate: onUpdateFacility,
    });
    // separate loading state to avoid affecting fetchAllFacilities
    const [isImportLoading, setImportLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [error, setError] = useState(undefined);
    const initialFacilityModalData = convertIFacilityToFacilityForm(facilityIdentity ? facility : undefined, facilityTemplate);
    const facilityUseForm = useForm({
        values: initialFacilityModalData,
        mode: 'onChange',
    });
    const imageRef = useRef(null);
    const { reset } = facilityUseForm;
    const modalSize = (_a = useBreakpointValue({
        base: 'lg',
        lg: '5xl',
    })) !== null && _a !== void 0 ? _a : 'lg';
    const tabLabelSize = (_b = useBreakpointValue({
        base: '20px',
        lg: '24px',
    })) !== null && _b !== void 0 ? _b : '20px';
    const handleFileSelect = (e) => {
        var _a, _b;
        const file = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        if (file) {
            const fileExtension = getFileExtension(file.name);
            if (fileExtension !== 'csv') {
                setError('Allowed file types are ".csv"');
            }
            setSelectedFile(file);
        }
    };
    const removeSelectedFile = () => {
        setSelectedFile(undefined);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setError(undefined);
    };
    const onSubmitFacility = (data) => {
        var _a;
        let photo;
        const files = (_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.files;
        // Check if there are any files - if so, the user wants to update
        // the facility image
        if (files && files.length > 0) {
            photo = {
                file: files[0],
            };
        }
        else if (facility === null || facility === void 0 ? void 0 : facility.photoName) {
            // If there are no files, keep the existing photo
            photo = {
                fileName: facility === null || facility === void 0 ? void 0 : facility.photoName,
            };
        }
        updateFacility(data, photo, facility === null || facility === void 0 ? void 0 : facility.identity, orgIdentity, facilityTemplate)
            .then(() => facilityDisclosure.onClose())
            .catch((e) => {
            console.error(e);
        });
    };
    const onSubmitImport = () => {
        if (selectedFile && importFacilities) {
            setImportLoading(true);
            importFacilities(selectedFile, orgIdentity)
                .then(() => {
                removeSelectedFile();
                facilityDisclosure.onClose();
            })
                .catch((e) => {
                console.error(e);
            })
                .finally(() => setImportLoading(false));
        }
    };
    useEffect(() => {
        if (facilityDisclosure.isOpen) {
            if (facilityIdentity) {
                fetchFacility(facilityIdentity);
            }
            else {
                reset(convertIFacilityToFacilityForm(undefined));
            }
        }
    }, [facilityDisclosure.isOpen, facilityIdentity]);
    const handleModalClose = () => {
        !isImportLoading && facilityDisclosure.onClose();
        removeSelectedFile();
        reset();
    };
    return (_jsxs(Form.HeadlessBareModal, Object.assign({ disclosure: facilityDisclosure, onClear: handleModalClose, labels: {
            title: '',
            apply: '',
            cancel: '',
        }, size: modalSize, closeOnOverlayClick: false }, { children: [_jsxs(Tabs, Object.assign({ w: '100%' }, { children: [_jsxs(TabList, Object.assign({ mb: 10 }, { children: [_jsx(Tab, Object.assign({ fontSize: tabLabelSize }, { children: facilityIdentity ? 'Edit Facility' : 'New Facility' })), !facilityIdentity && facilityTemplate && (_jsx(Tab, Object.assign({ fontSize: tabLabelSize }, { children: "Import Facilities" })))] })), _jsxs(TabPanels, { children: [_jsx(TabPanel, Object.assign({ pt: 0, pb: 0 }, { children: _jsx(FacilityFormInputs, { facilityTemplate: facilityTemplate, facilityForm: facilityUseForm, validateFacilityName: validateFacilityName, facility: facilityIdentity ? facility : undefined, onSubmitFacility: onSubmitFacility, isSaveDisabled: isFacilityLoading, imageRef: imageRef, imageName: facilityIdentity
                                        ? facility === null || facility === void 0 ? void 0 : facility.photoName
                                        : undefined, handleModalClose: handleModalClose }) })), !facility && !facilityIdentity && facilityTemplate && (_jsx(TabPanel, Object.assign({ pt: 0, pb: 0 }, { children: _jsx(ImportFacilities, { importTemplateUrl: facilityTemplate.importTemplateUrl, handleFileSelect: handleFileSelect, facilityDisclosure: facilityDisclosure, isSubmitDisabled: !selectedFile ||
                                        error !== undefined ||
                                        isImportLoading, onSubmitImport: onSubmitImport, fileInputRef: fileInputRef, selectedFile: selectedFile, removeSelectedFile: removeSelectedFile, error: error }) })))] })] })), _jsx(LoadingWithControl, { background: 'base.white', isLoading: isImportLoading || isFacilityLoading })] })));
};
