import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, GridItem } from '@chakra-ui/react';
import { canViewFacility } from '@frontend/domain/contexts/Authorization/Roles';
import { useFeatureFlag } from '@frontend/domain/contexts/FeatureFlags/FeatureFlagContext';
import MapView from '@frontend/shared/src/components/Location/MapView';
import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
import { FacilityCard } from '../../../components/display/FacilityCard';
import { FacilitiesPageInstructions } from './FacilitiesPageInstructions';
import { FacilityModal } from './FacilityModal';
const sortBySiteAndFacility = (facilityA, facilityB) => {
    const compareVal = facilityA.siteName.localeCompare(facilityB.siteName);
    if (compareVal === 0) {
        return facilityA.name.localeCompare(facilityB.name);
    }
    else {
        return compareVal;
    }
};
export const MyFacilities = ({ orgIdentity, facilities, facilityTemplate, canManageFacility, facilityDisclosure, onUpdateFacility, importFacilities, deleteFacility, user, }) => {
    const googleMapsFF = useFeatureFlag('FF_GOOGLE_MAP_VIEW');
    const canRenderFacilities = facilities && facilities.length > 0;
    if (canRenderFacilities) {
        facilities === null || facilities === void 0 ? void 0 : facilities.sort(sortBySiteAndFacility);
    }
    const validateFacilityName = (value, facilityIdentity, siteName) => {
        const normalizedValue = value.trim().toLowerCase();
        const facilityFound = (facilities || [])
            .filter((facility) => facility.siteName === siteName)
            .find((existingFacility) => existingFacility.name.trim().toLowerCase() ===
            normalizedValue &&
            existingFacility.identity !== facilityIdentity);
        if (facilityFound) {
            return 'A facility with this name has already been created. Please use a unique name.';
        }
        else {
            return undefined;
        }
    };
    return (_jsxs(_Fragment, { children: [canRenderFacilities ? (_jsxs(Grid, Object.assign({ "data-testid": 'myFacilitiesContainer', gap: 8, p: 8, templateColumns: {
                    base: 'repeat(1, 1fr)',
                    lg: `repeat(${googleMapsFF ? 2 : 1}, 1fr)`,
                }, templateAreas: {
                    base: '"map" "facilities"',
                    lg: '"facilities map"',
                }, h: 'full', maxH: 'full', overflow: 'hidden' }, { children: [_jsx(GridItem, Object.assign({ overflow: 'scroll', area: 'facilities' }, { children: _jsx(Grid, Object.assign({ p: 1, "data-testid": 'myFacilities', gap: 8, templateColumns: {
                                base: 'repeat(1, 1fr)',
                                lg: `repeat(${googleMapsFF ? 1 : 2}, 1fr)`,
                            } }, { children: facilities.map((facility) => {
                                const facilityRoute = AppPaths.facilities.withFacilityId({
                                    orgIdentity,
                                    facilityIdentity: facility.identity,
                                });
                                return (_jsx(GridItem, Object.assign({ colSpan: 1 }, { children: _jsx(FacilityCard, { linkTo: facilityRoute, facility: facility, orgIdentity: orgIdentity, canManageFacility: canManageFacility, facilityTemplate: facilityTemplate, validateFacilityName: validateFacilityName, onFacilityUpdate: onUpdateFacility, deleteFacility: deleteFacility, canViewFacility: user
                                            ? canViewFacility(user, orgIdentity, facility.identity)
                                            : false }) }), facility.identity));
                            }) })) })), googleMapsFF ? (_jsx(GridItem, Object.assign({ area: 'map', h: { base: '35vh', lg: 'full' }, rounded: 'lg', overflow: 'hidden' }, { children: _jsx(MapView, { pins: facilities.map((facility) => ({
                                lat: facility.location.latitude,
                                lng: facility.location.longitude,
                                data: facility,
                            })) }) }))) : null] }))) : (_jsx(FacilitiesPageInstructions, { canAdd: canManageFacility })), _jsx(FacilityModal, { facilityIdentity: undefined, facilityDisclosure: facilityDisclosure, facilityTemplate: facilityTemplate, orgIdentity: orgIdentity, validateFacilityName: validateFacilityName, onUpdateFacility: onUpdateFacility, importFacilities: importFacilities })] }));
};
