var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { Heading, Spacer, Stack } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAssetContext } from '../../../../../contexts/Assets/AssetContext';
import { useTemplateContext } from '../../../../../contexts/Template/TemplateContext';
import { convertAssetToAssetForm } from '../../../../../conversions/AssetConversions';
import { PageModes } from '../../../../../objects/FCAInterfaces';
import { SpaceFormButtons } from '../Space/SpaceFormButtons';
import { AssetDetails } from './AssetDetails';
export const SidebarAsset = ({ orgIdentity, facilityId, spaceId, canEdit, }) => {
    const { pageMode, drawAssetPin, setPageMode, selectedSpaceIdentity, updateCurrentSpacesWithAsset, isDataLoading, setIsDataLoading, selectAsset, } = useFacilityContext();
    const { asset, submitAssetForm } = useAssetContext();
    const { getAssetTemplateVersionByClass, getAssetTemplateByClass, assetTemplates, } = useTemplateContext();
    const isAddingAsset = pageMode === PageModes.ADD_ASSET;
    const isEditingAsset = pageMode === PageModes.EDIT;
    const addingOrUpdating = isAddingAsset || isEditingAsset;
    const assetUseForm = useForm({ mode: 'onChange' });
    useEffect(() => {
        if (pageMode === PageModes.ADD_ASSET) {
            assetUseForm.reset(convertAssetToAssetForm(undefined));
        }
        else {
            const assetTemplate = getAssetTemplateByClass(asset === null || asset === void 0 ? void 0 : asset.assetClass);
            assetUseForm.reset(convertAssetToAssetForm(asset, asset === null || asset === void 0 ? void 0 : asset.name, assetTemplate));
        }
    }, [asset, pageMode, assetTemplates]);
    const onSubmit = (formData) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedSpaceIdentity && addingOrUpdating) {
            setIsDataLoading(true);
            // use updated pin or existing pin
            if (pageMode === PageModes.EDIT && !drawAssetPin) {
                formData.location = asset === null || asset === void 0 ? void 0 : asset.pin;
            }
            else {
                formData.location = drawAssetPin;
            }
            // only update form if save is successful
            const savedAsset = yield submitAssetForm(formData, selectedSpaceIdentity, getAssetTemplateVersionByClass)
                .then((asset) => {
                if (asset) {
                    updateCurrentSpacesWithAsset(asset);
                    selectAsset(asset.identity, asset.spaceIdentity);
                    setPageMode(PageModes.VIEW);
                    return asset;
                }
            })
                .finally(() => setIsDataLoading(false));
            if (!savedAsset)
                return; //don't clear form if error
        }
    });
    const handleCancel = () => {
        assetUseForm.reset(convertAssetToAssetForm(asset));
        setPageMode(PageModes.VIEW);
    };
    return (_jsx(FormProvider, Object.assign({}, assetUseForm, { children: _jsxs("form", Object.assign({ style: {
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }, onSubmit: assetUseForm.handleSubmit(onSubmit), "data-testid": 'asset-form' }, { children: [_jsxs(Stack, Object.assign({ w: '100%', h: '100%', overflow: 'auto' }, { children: [_jsx(Heading, Object.assign({ as: 'h2', bg: 'misc.sectionGrey', fontWeight: '600', variant: variants.detailsSidebar }, { children: asset && !isAddingAsset ? asset.name : 'Add New Asset' })), _jsx(Stack, Object.assign({ w: '100%', h: '100%', overflow: 'auto', mb: '0px' }, { children: _jsx(AssetDetails, { orgId: orgIdentity, facilityId: facilityId, spaceId: spaceId, asset: asset, canEdit: canEdit }) }))] })), _jsx(Spacer, {}), (isAddingAsset || isEditingAsset) && (_jsx(SpaceFormButtons, { control: assetUseForm.control, handleCancel: handleCancel })), _jsx(LoadingWithControl, { isLoading: isDataLoading })] })) })));
};
