import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, Input, } from '@chakra-ui/react';
import { convertIConfigureLocationToLocationString } from '@frontend/domain/models/Facility/FacilityConversions';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useEffect, useState } from 'react';
const getPlaceField = (type, addressComponents) => {
    const found = addressComponents.find((component) => component.types.includes(type));
    return found;
};
const getAddress1 = (addressItems) => {
    var _a, _b, _c;
    const streetNumber = (_a = getPlaceField('street_number', addressItems)) === null || _a === void 0 ? void 0 : _a.long_name;
    const streetName = ((_b = getPlaceField('route', addressItems)) === null || _b === void 0 ? void 0 : _b.short_name) ||
        ((_c = getPlaceField('premise', addressItems)) === null || _c === void 0 ? void 0 : _c.long_name);
    if (streetName) {
        return streetNumber ? `${streetNumber} ${streetName}` : streetName;
    }
    else {
        return undefined;
    }
};
const getAddress2 = (addressItems) => {
    var _a;
    return (_a = getPlaceField('subpremise', addressItems)) === null || _a === void 0 ? void 0 : _a.long_name;
};
const getPostalCode = (addressItems) => {
    var _a;
    return (_a = getPlaceField('postal_code', addressItems)) === null || _a === void 0 ? void 0 : _a.long_name;
};
export const convertPlaceToIConfigureLocation = (place) => {
    var _a, _b;
    const addressItems = place.address_components;
    try {
        if (addressItems && addressItems.length > 0) {
            // object validation happens in form
            return {
                address1: getAddress1(addressItems) || '',
                address2: getAddress2(addressItems),
                city: ((_a = getPlaceField('locality', addressItems)) === null || _a === void 0 ? void 0 : _a.long_name) || '',
                stateOrProvince: ((_b = getPlaceField('administrative_area_level_1', addressItems)) === null || _b === void 0 ? void 0 : _b.short_name) || '',
                postalCode: getPostalCode(addressItems) || '',
            };
        }
        else {
            return undefined;
        }
    }
    catch (err) {
        console.warn(err);
        return undefined;
    }
};
// This is an example of the classic "Place Autocomplete" widget.
// https://developers.google.com/maps/documentation/javascript/place-autocomplete
export const AddressAutofillInput = ({ label, location, inputRef, onPlaceSelect, onAutofillReset, variant, constraints, customStyles, errorMessage, }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const [locationSearchString, setLocationSearchString] = useState();
    const places = useMapsLibrary('places');
    useEffect(() => {
        setLocationSearchString(convertIConfigureLocationToLocationString(location));
    }, [location]);
    useEffect(() => {
        if (!places || !inputRef.current)
            return;
        const options = {
            fields: [
                'geometry',
                'formatted_address',
                'address_components',
                'place_id', // id for place details lookup
            ],
            types: ['address'],
            componentRestrictions: { country: 'us' },
        };
        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);
    useEffect(() => {
        if (!placeAutocomplete)
            return;
        placeAutocomplete.addListener('place_changed', () => {
            const place = placeAutocomplete.getPlace();
            const newLocation = convertPlaceToIConfigureLocation(place);
            onPlaceSelect(newLocation);
        });
    }, [onPlaceSelect, placeAutocomplete]);
    const onLocationSearchStringChange = (value) => {
        setLocationSearchString(value);
        if (location === null || location === void 0 ? void 0 : location.address1) {
            onAutofillReset();
        }
    };
    return (_jsxs(FormControl, Object.assign({ isRequired: constraints === null || constraints === void 0 ? void 0 : constraints.required, isInvalid: !!errorMessage }, { children: [_jsx(FormLabel, Object.assign({ pl: '0px', style: customStyles === null || customStyles === void 0 ? void 0 : customStyles.label }, { children: label })), _jsx(Input, { onChange: (e) => onLocationSearchStringChange(e.target.value), ref: inputRef, variant: variant, defaultValue: locationSearchString, style: customStyles === null || customStyles === void 0 ? void 0 : customStyles.input, "data-testid": 'address-input' }), _jsx(FormErrorMessage, { children: errorMessage })] })));
};
