import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, HStack, useToken, } from '@chakra-ui/react';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import { default as React, useRef } from 'react';
import { StandardAssetError, StandardAssetType } from './StandardAssetType';
export const StandardAsset = ({ assetClassTemplate, addNewAsset, expandedAssetIndex, setExpandedAssetIndex, classIndex, spaceIdentity, spaceName, orgIdentity, fields, standardAssets, assetTemplates, canEdit, isEditing, removeAsset, control, trigger, getValues, }) => {
    const [orange, blue] = useToken('colors', [
        'primary.orange.500',
        'primary.blue.500',
    ]);
    const assetClass = assetClassTemplate.assetClass;
    const assetClassFields = fields.filter((field) => field.assetClass === assetClass);
    const canAdd = canEdit &&
        (assetClassTemplate === null || assetClassTemplate === void 0 ? void 0 : assetClassTemplate.maxCount) !== undefined &&
        assetClassFields.length < assetClassTemplate.maxCount;
    const defaultExpandedIndex = assetClassFields.length > 1 ? [] : [0];
    const parentRef = useRef(null); // used for action menu positioning
    return (_jsxs(AccordionItem, Object.assign({ ref: parentRef, "data-testid": `${assetClass}-accordion`, position: 'relative' }, { children: [_jsxs(AccordionButton, Object.assign({ "data-testid": `${assetClass}-accordion-button`, onClick: (e) => {
                    if (assetClassFields.length === 0) {
                        e.preventDefault();
                    }
                }, _expanded: {
                    borderBottom: assetClassFields.length > 0
                        ? `1px solid ${orange}`
                        : 'none',
                } }, { children: [_jsxs(HStack, Object.assign({ flex: '1' }, { children: [_jsx(Body, Object.assign({ textAlign: 'left', color: blue }, { children: assetClass })), _jsx(StandardAssetError, { control: control, fields: fields, assetClass: assetClass })] })), _jsxs(HStack, Object.assign({ gap: 1 }, { children: [_jsx(AccordionIcon, { "data-testid": `${assetClass}-accordion-expand-icon`, visibility: assetClassFields.length > 0 ? 'visible' : 'hidden' }), _jsx(AddIcon, { "data-testid": 'add-new-asset', boxSize: 4, w: 2.5, h: 2.5, visibility: canAdd ? 'visible' : 'hidden', onClick: (e) => addNewAsset(e, classIndex, assetClassTemplate, assetClassFields) })] }))] })), _jsx(AccordionPanel, Object.assign({ padding: 0, overflow: 'visible' }, { children: _jsx(Accordion, Object.assign({ allowToggle: true, index: expandedAssetIndex !== undefined
                        ? [expandedAssetIndex]
                        : defaultExpandedIndex, onChange: (index) => {
                        setExpandedAssetIndex(Array.isArray(index) ? index[0] : index);
                        trigger('standardAssets');
                    }, border: assetClassFields.length > 0
                        ? '8px solid rgba(0, 0, 0, 0.25)'
                        : 'none' }, { children: assetClassFields.map((field, assetTypeIndex) => {
                        const standardAssetIndex = fields.findIndex((f) => f === field);
                        return (_jsx(React.Fragment, { children: _jsx(StandardAssetType, { standardAssetIndex: standardAssetIndex, assetTypeIndex: assetTypeIndex, standardAssets: standardAssets, spaceIdentity: spaceIdentity, spaceName: spaceName, assetTemplate: assetClassTemplate, assetTemplates: assetTemplates, canEdit: canEdit, isEditing: isEditing, orgIdentity: orgIdentity, removeAsset: removeAsset, parentRef: parentRef, control: control, trigger: trigger, getValues: getValues }) }, field.id));
                    }) })) }))] })));
};
