import { getLast } from '@/utils/arrayUtils';
import { convertRatingToHex } from '@/utils/spaceAssetsUtils';
import { SpaceDefinitionEnum } from '@frontend/clients/mysiteiq/models/SpaceDefinitionEnum';
import { AssetClassType, SpaceCategoryEnum, } from '../objects/FCAInterfaces';
import { convertAssetToAssetForm, stringToInt } from './AssetConversions';
export const extractQualityMetrics = (platformSpace) => {
    if (!platformSpace.qualityMetrics) {
        return {
            co2: undefined,
            light: undefined,
            sound: undefined,
            temperature: undefined,
            humidity: undefined,
            occupancy: undefined,
        };
    }
    const { cO2MetricHistory, lightMetricHistory, soundMetricHistory, temperatureMetricHistory, humidityMetricHistory, occupancyMetricHistory, } = platformSpace.qualityMetrics;
    const qualityMetrics = {
        co2: convertQualityMetricResponseToQualityMetric(cO2MetricHistory),
        light: convertQualityMetricResponseToQualityMetric(lightMetricHistory),
        sound: convertQualityMetricResponseToQualityMetric(soundMetricHistory),
        temperature: convertQualityMetricResponseToQualityMetric(temperatureMetricHistory),
        humidity: convertQualityMetricResponseToQualityMetric(humidityMetricHistory),
        occupancy: convertQualityMetricResponseToQualityMetric(occupancyMetricHistory),
    };
    return qualityMetrics;
};
export const convertSpaceDetailResponseToSpace = (platformSpace) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const qualityMetrics = extractQualityMetrics(platformSpace);
    return {
        id: '',
        spaceIdentity: platformSpace.spaceId,
        spaceId: platformSpace.spaceName,
        constrYear: (_a = platformSpace.constructionYear) !== null && _a !== void 0 ? _a : 0,
        spaceArea: (_b = platformSpace.spaceArea) !== null && _b !== void 0 ? _b : 0,
        definition: platformSpace.spaceCategory === SpaceDefinitionEnum.FACILITY
            ? SpaceCategoryEnum.FACILITY
            : SpaceCategoryEnum.GROUNDS,
        description: (_c = platformSpace.description) !== null && _c !== void 0 ? _c : '',
        note: (_d = platformSpace.note) !== null && _d !== void 0 ? _d : '',
        isAssessed: platformSpace.isAssessed,
        type: (_e = platformSpace.spaceType) !== null && _e !== void 0 ? _e : '',
        roomFunction: (_f = platformSpace.function) !== null && _f !== void 0 ? _f : '',
        occupied: qualityMetrics.occupancy
            ? qualityMetrics.occupancy.value > 0
            : false,
        numberOfOccupants: (_h = (_g = qualityMetrics.occupancy) === null || _g === void 0 ? void 0 : _g.value) !== null && _h !== void 0 ? _h : 0,
        shape: platformSpace.points.map((point) => ({
            x: point.xPosition,
            y: point.yPosition,
            pointIndex: point.sequence,
        })),
        light: qualityMetrics.light,
        sound: qualityMetrics.sound,
        co2: qualityMetrics.co2,
        temperature: qualityMetrics.temperature,
        humidity: qualityMetrics.humidity,
        facilityAssets: platformSpace.servedBy.map((facilityAsset) => facilityAsset.assetId),
    };
};
const createStandardAssetsForSpaceForm = (assetTemplates, standardAssets) => {
    const assetFormInterfaces = [];
    for (const assetTemplate of assetTemplates) {
        const standardAssetsForClass = standardAssets.filter((standardAsset) => standardAsset.assetClass === assetTemplate.assetClass);
        for (const standardAsset of standardAssetsForClass) {
            const name = standardAsset.name || assetTemplate.assetClass;
            assetFormInterfaces.push(convertAssetToAssetForm(standardAsset, name, assetTemplate));
        }
    }
    return assetFormInterfaces;
};
export const convertSpaceToSpaceForm = (space, standardAssets, assetTemplates) => {
    var _a;
    const spaceForm = {
        identity: (space === null || space === void 0 ? void 0 : space.spaceIdentity) || undefined,
        name: (space === null || space === void 0 ? void 0 : space.spaceId) || '',
        definition: (space === null || space === void 0 ? void 0 : space.definition) || SpaceCategoryEnum.FACILITY,
        type: (space === null || space === void 0 ? void 0 : space.type) || '',
        constructionYear: (space === null || space === void 0 ? void 0 : space.constrYear) ? space.constrYear.toString() : '',
        spaceArea: (space === null || space === void 0 ? void 0 : space.spaceArea) ? space.spaceArea.toString() : '',
        isAssessed: (space === null || space === void 0 ? void 0 : space.isAssessed) || false,
        roomFunction: (space === null || space === void 0 ? void 0 : space.roomFunction) || '',
        description: (space === null || space === void 0 ? void 0 : space.description) || '',
        note: (space === null || space === void 0 ? void 0 : space.note) || '',
        standardAssets: assetTemplates.length > 0
            ? createStandardAssetsForSpaceForm(assetTemplates, standardAssets || [])
            : [],
        facilityAssignedAssets: (space === null || space === void 0 ? void 0 : space.facilityAssets) || [],
        cO2: {
            co2Value: (space === null || space === void 0 ? void 0 : space.co2) ? space.co2.value.toString() : '',
            occupancyNumber: (_a = space === null || space === void 0 ? void 0 : space.numberOfOccupants) !== null && _a !== void 0 ? _a : 0,
        },
        light: (space === null || space === void 0 ? void 0 : space.light) ? space.light.value.toString() : '',
        sound: (space === null || space === void 0 ? void 0 : space.sound) ? space.sound.value.toString() : '',
        temperature: (space === null || space === void 0 ? void 0 : space.temperature)
            ? space.temperature.value.toString()
            : '',
        humidity: (space === null || space === void 0 ? void 0 : space.humidity) ? space.humidity.value.toString() : '',
    };
    return spaceForm;
};
export const convertToSpaceSummaryWithNonStandardAssets = (spaceSummary) => {
    return {
        name: spaceSummary.spaceName,
        identity: spaceSummary.spaceId,
        location: spaceSummary.points.map((point) => ({
            x: point.xPosition,
            y: point.yPosition,
            pointIndex: point.sequence,
        })),
        floorplanIdentity: spaceSummary.floorPlanId,
        isAssessed: spaceSummary.isAssessed,
        category: getSpaceCategoryEnumFromString(spaceSummary.spaceCategory),
        assets: spaceSummary.assets
            .filter((asset) => asset.assetClassType !== AssetClassType.STANDARD)
            .map((asset) => convertAssetSummaryResponseToAssetSummary(asset)),
    };
};
const getSpaceCategoryEnumFromString = (category) => {
    if (category === 'GROUND' ||
        category === SpaceCategoryEnum.GROUNDS.toString()) {
        return SpaceCategoryEnum.GROUNDS;
    }
    else {
        return SpaceCategoryEnum.FACILITY;
    }
};
const convertAssetSummaryResponseToAssetSummary = (assetSummary) => {
    return {
        identity: assetSummary.assetId,
        name: assetSummary.assetName,
        location: assetSummary.pin
            ? {
                x: assetSummary.pin.xPosition,
                y: assetSummary.pin.yPosition,
            }
            : undefined,
    };
};
export const convertQualityMetricResponseToQualityMetric = (metrics) => {
    if (!metrics || metrics.length === 0)
        return undefined;
    const lastValue = getLast(metrics);
    return {
        value: lastValue ? lastValue.value : 0,
        rating: convertRatingToHex(lastValue === null || lastValue === void 0 ? void 0 : lastValue.rating),
        allValues: metrics,
    };
};
export const convertSpaceToSpaceSummary = (space) => {
    return {
        identity: space.spaceIdentity,
        name: space.spaceId,
        location: space.shape,
        isAssessed: space.isAssessed,
        category: space.definition,
        assets: [],
    };
};
export const convertSpaceFormToSpaceEvaluationRequest = (floorPlanId, spaceForm, spaceShape) => {
    var _a, _b;
    return {
        spaceId: spaceForm.identity,
        floorPlanId: floorPlanId,
        spaceCategory: spaceForm.definition,
        isAssessed: spaceForm.isAssessed || false,
        specification: {
            spaceType: spaceForm.type ? spaceForm.type : null,
            spaceName: spaceForm.name || 'Untitled',
            description: spaceForm.description ? spaceForm.description : null,
            constructionYear: stringToInt(spaceForm.constructionYear),
            spaceArea: stringToInt(spaceForm.spaceArea),
            spaceFunction: spaceForm.roomFunction
                ? spaceForm.roomFunction
                : null,
            note: spaceForm.note ? spaceForm.note : null,
        },
        points: spaceShape.map((point) => {
            return {
                xPosition: Math.round(point.x),
                yPosition: Math.round(point.y),
                sequence: point.pointIndex,
            };
        }),
        qualityMetrics: {
            cO2Metric: stringToInt((_a = spaceForm.cO2) === null || _a === void 0 ? void 0 : _a.co2Value),
            lightMetric: stringToInt(spaceForm.light),
            occupancyMetric: (_b = spaceForm.cO2) === null || _b === void 0 ? void 0 : _b.occupancyNumber,
            soundMetric: stringToInt(spaceForm.sound),
            temperatureMetric: stringToInt(spaceForm.temperature),
            humidityMetric: stringToInt(spaceForm.humidity),
        },
        facilityAssets: spaceForm.facilityAssignedAssets,
    };
};
