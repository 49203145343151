import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CopyPasteProvider } from '@/contexts/CopyPaste/CopyPasteProvider';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Center, HStack, IconButton, ListItem, Spacer, Text, UnorderedList, useDisclosure, } from '@chakra-ui/react';
import { ActionMenu } from '@frontend/design-system/components/ActionMenu/ActionMenu';
import { CanvasMode } from '@frontend/design-system/components/FloorPlan/FloorPlanCanvas';
import { slgqColors, variants } from '@frontend/design-system/theme/theme';
import { useEffect, useRef, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaCopy, FaTrash } from 'react-icons/fa';
import { appColors } from '../../../../config/constants';
import { SpaceIcon } from '../../../../config/icons';
import { useFacilityContext } from '../../../../contexts/Facilities/FacilityPageContext';
import { PageModes, } from '../../../../objects/FCAInterfaces';
import { DeleteSpaceModal } from '../DetailsSidebar/Space/DeleteSpaceModal';
import { AssetSidebarItem } from './AssetSidebarItem';
import { CopySpace } from './CopyModal/CopySpace';
import { SidebarItem } from './SidebarItem';
import useLongPress from './useLongPress';
export const SpaceSidebarItem = ({ plans, space, canEdit, scrolling, setScrolling, scrollingAuto, setScrollingAuto, facilityIdentity, }) => {
    const [expanded, setExpanded] = useState(false);
    const [isOpenActions, setIsOpenActions] = useState(false);
    const { currentSpaces, selectedSpaceIdentity, setPageMode, setCanvasMode, selectSpace, pageMode, } = useFacilityContext();
    const spaceRef = useRef(null);
    const isAddingAsset = pageMode === PageModes.ADD_ASSET;
    const { isOpen: isOpenSpaceDelete, onOpen: onOpenSpaceDelete, onClose: onCloseSpaceDelete, } = useDisclosure();
    const { isOpen: isOpenSpaceCopy, onOpen: onOpenSpaceCopy, onClose: onCloseSpaceCopy, } = useDisclosure();
    const actions = [
        {
            name: 'Redraw Shape',
            icon: _jsx(BiEdit, { fontSize: '18px' }),
            display: 'block',
            handleClick: () => {
                setCanvasMode(CanvasMode.DRAW_SPACE_LOCATION);
                setIsOpenActions(false);
            },
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    setCanvasMode(CanvasMode.DRAW_SPACE_LOCATION);
                    setIsOpenActions(false);
                }
            },
        },
        {
            name: 'Edit Shape',
            icon: _jsx(BiEdit, { fontSize: '18px' }),
            display: 'block',
            handleClick: () => {
                setCanvasMode(CanvasMode.EDIT_SPACE_LOCATION);
                setIsOpenActions(false);
            },
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    setCanvasMode(CanvasMode.EDIT_SPACE_LOCATION);
                    setIsOpenActions(false);
                }
            },
        },
        {
            name: 'Copy',
            icon: _jsx(FaCopy, { fontSize: '18px' }),
            display: 'block',
            handleClick: () => onOpenSpaceCopy(),
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    onOpenSpaceCopy();
                    setIsOpenActions(false);
                }
            },
        },
        {
            name: 'Delete',
            icon: _jsx(FaTrash, { fontSize: '18px' }),
            display: 'block',
            handleClick: () => onOpenSpaceDelete(),
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    onOpenSpaceDelete();
                    setIsOpenActions(false);
                }
            },
        },
    ];
    useEffect(() => {
        var _a, _b;
        // Scrolls space to the Current Space.
        if (scrollingAuto) {
            setScrollingAuto(false);
        }
        else {
            if (spaceRef && selectedSpaceIdentity) {
                if (space.identity === selectedSpaceIdentity) {
                    (_a = spaceRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }
        }
        // Scrolls to top if there is no Current Space.
        if (spaceRef &&
            selectedSpaceIdentity === undefined &&
            currentSpaces.length > 0) {
            if (space.identity === currentSpaces[0].identity) {
                (_b = spaceRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [selectedSpaceIdentity]);
    useEffect(() => {
        if (isAddingAsset && space.identity === selectedSpaceIdentity) {
            setExpanded(true);
        }
    }, [isAddingAsset]);
    const resetPageModeView = () => {
        setPageMode(PageModes.VIEW);
    };
    const handleTouchMove = () => {
        if (!scrolling) {
            setScrolling(true);
        }
        if (!scrollingAuto) {
            setScrollingAuto(true);
        }
    };
    const handleMouseDown = () => {
        resetPageModeView();
        selectSpace(space.identity);
    };
    const handleTouchEnd = () => {
        if (scrolling) {
            setScrolling(false);
        }
        else {
            resetPageModeView();
            selectSpace(space.identity);
        }
    };
    const handleContextMenu = () => {
        if (scrolling) {
            setScrolling(false);
        }
        else {
            resetPageModeView();
            setIsOpenActions(true);
        }
    };
    const onLongPress = () => {
        setIsOpenActions(true);
    };
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, defaultOptions);
    return (_jsxs(_Fragment, { children: [_jsx(SidebarItem, { children: _jsxs(ListItem, Object.assign({ ref: spaceRef, position: 'relative', onContextMenu: (e) => e.preventDefault() }, { children: [_jsxs(HStack, Object.assign({ onTouchEnd: handleTouchEnd, onMouseDown: handleMouseDown, fontSize: 14, minH: '36px', w: '100%', spacing: '15px', bg: space.identity === selectedSpaceIdentity
                                ? appColors.KONVA_BLUE
                                : undefined, fontWeight: space.identity === selectedSpaceIdentity
                                ? 600
                                : undefined, borderBottom: space.identity === selectedSpaceIdentity
                                ? '1px solid #C0C2C1'
                                : undefined, "data-testid": `sidebar-space-item-${space.identity}` }, { children: [_jsx(Center, Object.assign({ w: '25px' }, { children: _jsx(SpaceIcon, {}) })), _jsxs(HStack, Object.assign({}, longPressEvent, { onContextMenu: handleContextMenu, w: '100%', userSelect: 'none' }, { children: [_jsx(Text, { children: space.name }), _jsx(Spacer, {}), space.assets.length > 0 && (_jsx(IconButton, { onClick: () => setExpanded(!expanded), onTouchEnd: () => setExpanded(!expanded), "aria-label": `expand/collapse assets for ${space.name}`, "data-testid": `show-hide-asset-in-${space.name}`, icon: !expanded ? (_jsx(ChevronDownIcon, { color: slgqColors.secGrey, boxSize: 6 })) : (_jsx(ChevronUpIcon, { color: slgqColors.secGrey, boxSize: 6 })), variant: variants.transparentBtn, boxSize: '32px' }))] })), canEdit && (_jsx(ActionMenu, { actions: actions, isOpenActions: isOpenActions, onCloseActions: () => setIsOpenActions(false), handleTouchMove: handleTouchMove, uniqueStyles: {
                                        top: 8,
                                    } }))] })), expanded && (_jsx(UnorderedList, { children: space.assets
                                .slice()
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((asset, i) => (_jsx(AssetSidebarItem, { plans: plans, asset: asset, canEdit: canEdit, spaceIdentity: space.identity, scrolling: scrolling, setScrolling: setScrolling, scrollingAuto: scrollingAuto, setScrollingAuto: setScrollingAuto, facilityIdentity: facilityIdentity }, i))) }))] })) }), _jsx(DeleteSpaceModal, { isOpen: isOpenSpaceDelete, onClose: onCloseSpaceDelete }), facilityIdentity && (_jsx(CopyPasteProvider, Object.assign({ isModalOpen: isOpenSpaceCopy, copyType: 'Space', facilityIdentity: facilityIdentity, selectedSpaceIdentity: selectedSpaceIdentity }, { children: _jsx(CopySpace, { plans: plans, spaceToCopy: space, isOpen: isOpenSpaceCopy, onClose: onCloseSpaceCopy }) })))] }));
};
