import { jsx as _jsx } from "react/jsx-runtime";
import { Map, Marker, useMap } from '@vis.gl/react-google-maps';
import React, { useEffect, useState } from 'react';
const MapView = ({ pins }) => {
    const [markers, setMarkers] = useState([]);
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    });
    const map = useMap();
    useEffect(() => {
        setMarkers(pins);
    }, [pins]);
    useEffect(() => {
        if (window.google && map && markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            markers.forEach((marker) => bounds.extend(marker));
            map.fitBounds(bounds);
            const center = bounds.getCenter();
            setCenter({ lat: center.lat(), lng: center.lng() });
        }
    }, [map, markers]);
    return (_jsx(Map, Object.assign({ fullscreenControl: true, zoomControl: true, mapTypeControl: true, style: { height: '100%', width: '100%' }, defaultCenter: center, gestureHandling: 'greedy', disableDefaultUI: true }, { children: markers.map((marker, index) => (
        // Will have to wrap in custom component to add custom data to render during interaction (tooltip, etc)
        // Will have to wrap the <AdvancedMarker /> component for custom pin styles
        _jsx(Marker, { position: marker, onClick: () => console.log(marker.data) }, index))) })));
};
export default MapView;
