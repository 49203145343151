import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, HStack, useDisclosure, useToken, } from '@chakra-ui/react';
import { ActionMenu, } from '@frontend/design-system/components/ActionMenu/ActionMenu';
import { DeleteModal } from '@frontend/design-system/components/DeleteModal/DeleteModal';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import React, { useEffect, useRef, useState } from 'react';
import { useFormState, } from 'react-hook-form';
import { FaTrash } from 'react-icons/fa';
import { MdReportProblem } from 'react-icons/md';
import useLongPress from '../../../SidebarItems/useLongPress';
import { StandardAssetsDetails } from './StandardAssetsDetails';
export const StandardAssetType = ({ standardAssetIndex, assetTypeIndex, standardAssets, spaceIdentity, spaceName, assetTemplate, assetTemplates, canEdit, isEditing, orgIdentity, removeAsset, parentRef, control, trigger, getValues, }) => {
    const createAssetName = (assetClass, assetType, installYear) => {
        if (assetType) {
            return installYear ? `${assetType}-${installYear}` : assetType;
        }
        else {
            return assetClass;
        }
    };
    const [orange, blue] = useToken('colors', [
        'primary.orange.500',
        'primary.blue.500',
    ]);
    const [relativeTop, setRelativeTop] = useState(0);
    const accordionRef = useRef(null);
    const getAssetTypeName = () => {
        var _a, _b, _c;
        const fields = getValues('standardAssets');
        return createAssetName(assetTemplate.assetClass, (_a = fields[standardAssetIndex]) === null || _a === void 0 ? void 0 : _a.assetType, (_c = (_b = fields[standardAssetIndex]) === null || _b === void 0 ? void 0 : _b.approximateInstallYear) === null || _c === void 0 ? void 0 : _c.toString());
    };
    const [assetTypeName, setAssetTypeName] = useState(getAssetTypeName());
    useEffect(() => {
        // reset asset name if taken out of edit mode
        setAssetTypeName(getAssetTypeName());
    }, [isEditing]);
    const updateAssetTypeName = (year, assetType) => {
        setAssetTypeName(createAssetName(assetTemplate.assetClass, assetType, year));
    };
    const menuDisclosure = useDisclosure();
    const deleteModalDisclosure = useDisclosure();
    const setActionMenuRelativePosition = () => {
        const parentElement = parentRef.current;
        const childElement = accordionRef.current;
        if (parentElement && childElement) {
            const parentRect = parentElement.getBoundingClientRect();
            const childRect = childElement.getBoundingClientRect();
            setRelativeTop(childRect.top - parentRect.top);
        }
    };
    const handleContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setActionMenuRelativePosition();
        menuDisclosure.onOpen();
    };
    const longPressEvent = useLongPress(handleContextMenu, {
        shouldPreventDefault: false,
        delay: 500,
    });
    const assetNameString = assetTypeName
        ? `${assetTemplate.assetClass} - ${assetTypeName}`
        : assetTemplate.assetClass;
    const deleteText = `You are about to delete the asset ${assetNameString}. This action will permenantly delete all data associated with this asset.`;
    const deleteMenu = [
        {
            name: 'Delete',
            icon: _jsx(FaTrash, { fontSize: '18px' }),
            display: 'block',
            handleClick: deleteModalDisclosure.onOpen,
            handleTouch: deleteModalDisclosure.onOpen,
        },
    ];
    return (_jsx(_Fragment, { children: _jsxs(AccordionItem, Object.assign({ borderColor: 'default' }, { children: [_jsx(AccordionButton, Object.assign({ _expanded: {
                        borderBottom: `1px solid ${orange}`,
                    }, "data-testid": `${assetTypeName}-accordion-button` }, longPressEvent, { onContextMenu: handleContextMenu, userSelect: 'none' }, { children: _jsxs(HStack, Object.assign({ justifyContent: 'space-between', w: 'full' }, { children: [_jsxs(HStack, Object.assign({ flex: '1' }, { children: [_jsx(Body, Object.assign({ color: blue, textAlign: 'left' }, { children: assetTypeName })), _jsx(StandardAssetError, { control: control, standardAssetIndex: standardAssetIndex })] })), _jsx(AccordionIcon, {}), _jsx("div", { ref: accordionRef }), _jsx(ActionMenu, { isOpenActions: menuDisclosure.isOpen, onCloseActions: menuDisclosure.onClose, actions: deleteMenu, handleTouchMove: () => { }, uniqueStyles: {
                                    position: 'absolute',
                                    zIndex: 'dropdown',
                                    minW: '200px',
                                    top: `${relativeTop}`, // using chakra spacing does not work
                                } })] })) })), _jsxs(AccordionPanel, { children: [_jsx(StandardAssetsDetails, { standardAssets: standardAssets, spaceIdentity: spaceIdentity, spaceName: spaceName, assetTemplate: assetTemplate, assetTemplates: assetTemplates, index: standardAssetIndex, canEdit: canEdit, isEditing: isEditing, orgIdentity: orgIdentity, isFirstAssetOfClass: assetTypeIndex === 0, updateAssetTypeName: updateAssetTypeName }), _jsx(DeleteModal, { disclosure: deleteModalDisclosure, onDelete: () => removeAsset(standardAssetIndex, isEditing, assetTemplate).then(() => {
                                trigger('standardAssets');
                            }), text: deleteText, size: 'md' })] })] })) }));
};
export const StandardAssetError = ({ control, fields, standardAssetIndex, assetClass, }) => {
    var _a;
    const { errors } = useFormState({ control });
    const [red] = useToken('colors', ['ui.danger']);
    if (standardAssetIndex !== undefined) {
        return (_jsx(_Fragment, { children: ((_a = errors === null || errors === void 0 ? void 0 : errors.standardAssets) === null || _a === void 0 ? void 0 : _a[standardAssetIndex]) ? (_jsx(MdReportProblem, { color: red, "data-testid": 'report-icon' })) : null }));
    }
    if (assetClass !== undefined && fields) {
        const hasErrorForClass = fields.some((field, index) => {
            var _a;
            return (((_a = errors === null || errors === void 0 ? void 0 : errors.standardAssets) === null || _a === void 0 ? void 0 : _a[index]) &&
                field.assetClass === assetClass);
        });
        return _jsx(_Fragment, { children: hasErrorForClass ? _jsx(MdReportProblem, { color: red }) : null });
    }
    return null;
};
