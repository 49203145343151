var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { defaultOptions, usePlatformApiRequestHandler, } from '@frontend/domain/hooks/platformAPI/usePlatformApiRequestHandler';
import { toast } from '@frontend/domain/ToastContainer';
const convertAssetClassTriggeredBy = (triggeredBy) => {
    const triggeredByToReturn = triggeredBy.map((trigger) => {
        return {
            questionName: trigger.questionName,
            values: trigger.values,
        };
    });
    return triggeredByToReturn;
};
const convertAssetClassQuestion = (questions) => {
    const assetClassQuestionsToReturn = questions.map((q) => {
        return {
            name: q.name,
            label: q.label,
            inputType: q.inputType,
            valueType: q.valueType,
            isRequired: q.isRequired,
            minimumValue: q.minimumValue,
            maximumValue: q.maximumValue,
            choices: q.choices,
            triggeredBy: q.triggeredBy
                ? convertAssetClassTriggeredBy(q.triggeredBy)
                : undefined,
        };
    });
    return assetClassQuestionsToReturn;
};
const convertAssetTemplate = (template) => {
    if (template.length < 1) {
        throw new Error('No template provided');
    }
    return template.map((t) => {
        var _a, _b;
        return {
            assetClass: t.name,
            classType: t.classType,
            assetCategory: t.assetCategory,
            maxCount: (_a = t.maxCount) !== null && _a !== void 0 ? _a : undefined,
            survey: t.survey
                ? convertAssetClassQuestion(t.survey.questions)
                : undefined,
            assetTypes: t.assetTypes,
            version: ((_b = t.survey) === null || _b === void 0 ? void 0 : _b.versionId) || 1, // sensible default if no version found
        };
    });
};
export const useAssessmentTemplateApi = () => {
    const { platformApi } = useDependencies();
    const loadSpaceTemplateHook = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the space template',
        } }));
    const loadAssetTemplateHook = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the asset template',
        } }));
    const fetchSpaceTemplate = (spaceCategory, organizationId) => __awaiter(void 0, void 0, void 0, function* () {
        const spaceTemplateReturn = yield loadSpaceTemplateHook.handleResponse(platformApi.assessment.getApiV1AssessmentLoadSpace(spaceCategory, organizationId), (i) => i);
        return spaceTemplateReturn;
    });
    const fetchAssetTemplate = (spaceCategory, isStandard) => __awaiter(void 0, void 0, void 0, function* () {
        const assetTemplateReturn = yield loadAssetTemplateHook.handleResponse(platformApi.assessment.getApiV1AssessmentLoadAsset(spaceCategory, isStandard), convertAssetTemplate);
        return assetTemplateReturn;
    });
    return {
        loadSpaceTemplateHandler: {
            isLoading: loadSpaceTemplateHook.isLoading,
            error: loadSpaceTemplateHook.error,
            fetch: fetchSpaceTemplate,
        },
        loadAssetTemplateHandler: {
            isLoading: loadAssetTemplateHook.isLoading,
            error: loadAssetTemplateHook.error,
            fetch: fetchAssetTemplate,
        },
    };
};
