var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { convertQualityMetricResponseToQualityMetric } from '@/conversions/SpaceConversions';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { defaultOptions } from '@frontend/domain/hooks/mysiteiqAPI/useMysiteIqApiRequestHandler';
import { usePlatformApiRequestHandler } from '@frontend/domain/hooks/platformAPI/usePlatformApiRequestHandler';
import { toast } from '@frontend/domain/ToastContainer';
import { convertAssetPinRequestToLocation, getLatestAssetConditionAssessment, } from '../conversions/AssetConversions';
export const useMapApi = () => {
    const { platformApi } = useDependencies();
    const fetchMapInfoByPlan = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the map data',
        } }));
    const convertMapSpaceResponseToMapSpace = (spaces) => {
        return spaces.map((space) => {
            var _a, _b, _c, _d, _e, _f, _g;
            return {
                spaceIdentity: space.spaceId,
                constrYear: (_a = space.constructionYear) !== null && _a !== void 0 ? _a : 0,
                isAssessed: space.isAssessed,
                light: convertQualityMetricResponseToQualityMetric((_b = space.qualityMetrics) === null || _b === void 0 ? void 0 : _b.lightMetricHistory),
                sound: convertQualityMetricResponseToQualityMetric((_c = space.qualityMetrics) === null || _c === void 0 ? void 0 : _c.soundMetricHistory),
                co2: convertQualityMetricResponseToQualityMetric((_d = space.qualityMetrics) === null || _d === void 0 ? void 0 : _d.cO2MetricHistory),
                temperature: convertQualityMetricResponseToQualityMetric((_e = space.qualityMetrics) === null || _e === void 0 ? void 0 : _e.temperatureMetricHistory),
                humidity: convertQualityMetricResponseToQualityMetric((_f = space.qualityMetrics) === null || _f === void 0 ? void 0 : _f.humidityMetricHistory),
                roomFunction: (_g = space.spaceFunction) !== null && _g !== void 0 ? _g : '',
                shape: space.points.map((point) => ({
                    x: point.xPosition,
                    y: point.yPosition,
                    pointIndex: point.sequence,
                })),
                assets: convertMapAssetResponseToMapAsset(space.assets, space),
                // This is a list of assets serving the space, not a list of FWA located in the space
                facilityAssets: space.servedBy,
            };
        });
    };
    const convertMapAssetResponseToMapAsset = (assets, space) => {
        return assets
            ? assets.map((asset) => {
                var _a, _b;
                return ({
                    id: '',
                    assetCondition: (_a = getLatestAssetConditionAssessment(asset.conditions, false)) !== null && _a !== void 0 ? _a : undefined,
                    assetIdentity: asset.assetId,
                    spaceIdentity: space.spaceId,
                    indicator: asset.assetClassType,
                    assetType: asset.assetClass,
                    name: asset.assetName,
                    platformAssetType: asset.assetType,
                    ageAdjustedCondition: (_b = getLatestAssetConditionAssessment(asset.conditions, true)) !== null && _b !== void 0 ? _b : undefined,
                    pin: asset.pin
                        ? convertAssetPinRequestToLocation(asset.pin)
                        : undefined,
                });
            })
            : [];
    };
    const fetchSpacesForMaps = (planId) => __awaiter(void 0, void 0, void 0, function* () {
        const spaces = yield fetchMapInfoByPlan.handleResponse(platformApi.assessment.getApiV1AssessmentMap(planId), (x) => convertMapSpaceResponseToMapSpace(x));
        return spaces;
    });
    return {
        mapDetails: {
            isLoading: fetchMapInfoByPlan.isLoading,
            error: fetchMapInfoByPlan.error,
            fetch: fetchSpacesForMaps,
        },
    };
};
