import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { PageModes, } from '@/objects/FCAInterfaces';
import { HStack } from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DynamicInput } from '../../Inputs/DynamicInput';
export function AssetFormSubquestions({ path, assetQuestions, assetTemplate, control, isFirstAssetOfClass, }) {
    const { pageMode } = useFacilityContext();
    const { getValues, setValue } = useFormContext();
    const addingOrUpdating = pageMode === PageModes.ADD_ASSET || pageMode === PageModes.EDIT
        ? true
        : false;
    const handleAreaPercentageChange = (value, assetClass, questionIndex) => {
        const formAssets = getValues('standardAssets');
        const firstAssetIndex = formAssets.findIndex((asset) => asset.assetClass === assetClass);
        const assetsByClass = formAssets.filter((asset) => asset.assetClass === assetClass);
        if (assetsByClass.length === 2) {
            const remainingPercentage = 100 - value;
            setValue(`standardAssets.${firstAssetIndex}.questions.${questionIndex}.value`, remainingPercentage.toString());
        }
    };
    return (_jsx(_Fragment, { children: assetQuestions &&
            assetTemplate &&
            assetQuestions
                .filter((question) => question.name !== 'AreaPercentage' ||
                !isFirstAssetOfClass)
                .map((question, questionIndex) => {
                var _a;
                const foundQuestion = (_a = assetTemplate.survey) === null || _a === void 0 ? void 0 : _a.find((surveyQ) => surveyQ.name === question.name);
                if (foundQuestion) {
                    const isAreaPercentageQuestion = question.name === 'AreaPercentage';
                    return (_jsx(HStack, Object.assign({ justifyContent: 'space-between', w: '100%', "data-testid": `asset_form_subquestion_${foundQuestion.name}` }, { children: _jsx(Controller, { name: `${path}.${questionIndex}.value`, control: control, rules: {
                                required: foundQuestion.isRequired
                                    ? `'${foundQuestion.label}' must not be empty.`
                                    : undefined,
                                min: foundQuestion.minimumValue
                                    ? {
                                        value: foundQuestion.minimumValue,
                                        message: `'${foundQuestion.label}' must be greater than or equal to ${foundQuestion.minimumValue}.`,
                                    }
                                    : undefined,
                                max: foundQuestion.maximumValue
                                    ? {
                                        value: foundQuestion.maximumValue,
                                        message: `'${foundQuestion.label}' must be less than or equal to ${foundQuestion.maximumValue}.`,
                                    }
                                    : undefined,
                                validate: (value) => {
                                    var _a, _b;
                                    // validate required 'qty linear ft' inputs
                                    if (foundQuestion.isRequired &&
                                        foundQuestion.inputType ===
                                            'qty linear ft' &&
                                        value &&
                                        value.replace(/,/g, '') ===
                                            '') {
                                        return `'${foundQuestion.label}' must not be empty.`;
                                    }
                                    // prevent negatives
                                    if (value <
                                        ((_a = foundQuestion.minimumValue) !== null && _a !== void 0 ? _a : 0)) {
                                        return `'${foundQuestion.label}' must be greater than or equal to ${(_b = foundQuestion.minimumValue) !== null && _b !== void 0 ? _b : 0}.`;
                                    }
                                    return true;
                                },
                            }, render: ({ field, fieldState: { error }, }) => (_jsx(DynamicInput, { data: field.value, editMode: addingOrUpdating, inputType: foundQuestion.inputType, label: foundQuestion.label, choices: foundQuestion.choices, handleInputChange: (e) => {
                                    field.onChange(e);
                                    if (isAreaPercentageQuestion) {
                                        handleAreaPercentageChange(e, assetTemplate.assetClass, questionIndex);
                                    }
                                }, required: foundQuestion.isRequired, errorMessage: error === null || error === void 0 ? void 0 : error.message })) }) }), `asset_form_subquestion_${foundQuestion.name}`));
                }
                else {
                    return null;
                }
            }) }));
}
