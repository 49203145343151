import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, FormLabel, HStack, Input, Text, } from '@chakra-ui/react';
import BodyRegular from '@frontend/design-system/components/Typography/Body/Body';
import { variants } from '@frontend/design-system/theme/theme';
import { convertFtDecIntoFtIn } from '../../../../../config/FCAHelperFunctions';
export const QtyLinearFtDynamic = ({ editable, label, answer, onInputChange, required, errorMessage, }) => {
    const { feet, inches } = convertFtDecIntoFtIn(answer);
    const changeQtyLinearFt = ({ ftVal, inVal, }) => {
        onInputChange(ftVal + ',' + inVal);
    };
    /** Final update to ensure constraint of passsing 0 when value is empty */
    const blurQtyLinearFt = ({ ftVal, inVal, }) => {
        const feet = ftVal !== '' ? ftVal : '0';
        const inches = inVal !== '' ? inVal : '0';
        onInputChange(feet + ',' + inches);
    };
    return (_jsxs(FormControl, { children: [required ? (_jsxs(FormLabel, Object.assign({ variant: variants.fcaBaseStyle }, { children: [label, " ", _jsx("span", Object.assign({ style: { color: 'crimson' } }, { children: "*" }))] }))) : (_jsx(FormLabel, Object.assign({ variant: variants.fcaBaseStyle }, { children: label }))), _jsxs("fieldset", Object.assign({ "data-testid": 'linear-ft-input' }, { children: [_jsxs(HStack, { children: [_jsxs(Box, Object.assign({ w: '60%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, { children: [_jsx(Input, { id: 'qty-linear-ft', position: editable ? 'unset' : 'absolute', visibility: editable ? 'visible' : 'hidden', pointerEvents: editable ? 'auto' : 'none', value: feet, fontSize: '14px', type: 'number', onWheel: (e) => e.target.blur(), onChange: (e) => {
                                            const feet = parseInt(e.target.value);
                                            if (!feet) {
                                                changeQtyLinearFt({
                                                    ftVal: '',
                                                    inVal: inches,
                                                });
                                            }
                                            else {
                                                changeQtyLinearFt({
                                                    ftVal: e.target.value,
                                                    inVal: inches,
                                                });
                                            }
                                        }, onBlur: (e) => {
                                            const parsedFeet = parseInt(e.target.value);
                                            if (parsedFeet && parsedFeet < 0) {
                                                blurQtyLinearFt({
                                                    ftVal: '0',
                                                    inVal: inches,
                                                });
                                            }
                                            else {
                                                blurQtyLinearFt({
                                                    ftVal: e.target.value,
                                                    inVal: inches,
                                                });
                                            }
                                        } }), !editable && (_jsx(Text, Object.assign({ variant: variants.detailsInputText }, { children: feet }))), _jsx(FormLabel, Object.assign({ ml: '5px', mb: '0px', htmlFor: 'qty-linear-ft', variant: variants.fcaBaseStyle }, { children: "ft" }))] })), _jsxs(Box, Object.assign({ w: '40%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, { children: [_jsx(Input, { id: 'qty-linear-in', position: editable ? 'unset' : 'absolute', visibility: editable ? 'visible' : 'hidden', pointerEvents: editable ? 'auto' : 'none', value: inches, fontSize: '14px', type: 'number', onWheel: (e) => e.target.blur(), onChange: (e) => {
                                            const inches = parseInt(e.target.value);
                                            if (!inches) {
                                                changeQtyLinearFt({
                                                    ftVal: feet,
                                                    inVal: '',
                                                });
                                            }
                                            else {
                                                changeQtyLinearFt({
                                                    ftVal: feet,
                                                    inVal: e.target.value,
                                                });
                                            }
                                        }, onBlur: (e) => {
                                            const parseInches = parseInt(e.target.value);
                                            if (parseInches && parseInches > 11) {
                                                blurQtyLinearFt({
                                                    ftVal: feet,
                                                    inVal: '11',
                                                });
                                            }
                                            else if (parseInches && parseInches < 0) {
                                                blurQtyLinearFt({
                                                    ftVal: feet,
                                                    inVal: '0',
                                                });
                                            }
                                            else {
                                                blurQtyLinearFt({
                                                    ftVal: feet,
                                                    inVal: e.target.value,
                                                });
                                            }
                                        } }), !editable && (_jsx(Text, Object.assign({ variant: variants.detailsInputText }, { children: inches }))), _jsx(FormLabel, Object.assign({ ml: '5px', mb: '0px', htmlFor: 'qty-linear-in', variant: variants.fcaBaseStyle }, { children: "in" }))] }))] }), editable && errorMessage && (_jsx(BodyRegular, Object.assign({ color: 'ui.danger' }, { children: errorMessage })))] }))] }));
};
