import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, FormControl, FormLabel, Switch, Text, } from '@chakra-ui/react';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import { variants } from '@frontend/design-system/theme/theme';
import { appColors } from '../../../../../../config/constants';
export const FacilityAssetSelector = ({ allFacilityAssetsList, facilityAssets, onChange, }) => {
    return (_jsxs(Box, Object.assign({ p: '10px 0px' }, { children: [_jsx(Text, Object.assign({ mb: '4px', fontWeight: 600, fontSize: '14px', color: appColors.PRIM_BLUE }, { children: "Facility Assigned Assets" })), _jsx(FormControl, { children: allFacilityAssetsList.map((asset, i) => (_jsxs(Flex, Object.assign({ alignItems: 'center', mb: '8px' }, { children: [_jsx(Switch, { id: `assigned-asset-switch-${i}`, colorScheme: 'orange', size: 'sm', mr: '8px', onChange: () => {
                                onChange(asset.identity);
                            }, isChecked: facilityAssets &&
                                facilityAssets.includes(asset.identity) }), _jsx(FormLabel, Object.assign({ mb: '0', fontWeight: 'normal', fontSize: '16px', htmlFor: `assigned-asset-switch-${i}`, variant: variants.fcaBaseStyle }, { children: _jsx(Body, Object.assign({ color: 'primary.blue.500' }, { children: asset.name })) }))] }), `facilityAssignedAssets-${i}`))) })] })));
};
