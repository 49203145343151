var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { toast } from '@frontend/domain/ToastContainer';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSelector } from '../../../../../../components/forms/FormSelector';
import { getListOfUniqueValues, resetFilterMoveForm, } from '../../../../../../config/FCAHelperFunctions';
import { useGalleryContext } from '../../../../../../contexts/Photo/GalleryContext';
import { usePhotoContext } from '../../../../../../contexts/Photo/PhotoContext';
import { usePhotoSelector } from '../../../../../../contexts/Photo/PhotoSelectContext';
import { movePhotosLocation } from '../../../../../../services/api/photoAPI';
export const MovePhotoModal = ({ isOpen, close, open, }) => {
    var _a, _b;
    const { stopSelecting, selectedPhotos } = usePhotoSelector();
    const { getPhotos } = usePhotoContext();
    const { facilityDropdown, spaceDropdown, assetCategoryDropdown, assetTypeDropdown, assetDropdown, initializeDropdowns, onFacilityDropdownSelection, onSpaceDropdownSelection, onAssetCategoryDropdownSelection, onAssetTypeDropdownSelection, } = useGalleryContext();
    const [canSave, setCanSave] = useState(false);
    const { handleSubmit, control, watch, getValues, reset, resetField } = useForm();
    useEffect(() => {
        if (isOpen) {
            initializeDropdowns(getValues());
        }
    }, [isOpen]);
    const onClose = () => {
        stopSelecting();
        close();
        reset();
    };
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (value.Facility || value.Space || value.Asset) {
                setCanSave(true);
            }
            else
                setCanSave(false);
            switch (name) {
                case 'Facility':
                    {
                        const optionsByFacility = onFacilityDropdownSelection(value);
                        resetFilterMoveForm('Facility', resetField, value, {
                            Space: optionsByFacility.Space,
                            Category: optionsByFacility.Category,
                            'Asset Type': optionsByFacility['Asset Type'],
                            Asset: optionsByFacility.Asset,
                        });
                        const values = getValues();
                        onFacilityDropdownSelection(values);
                    }
                    break;
                case 'Space':
                    {
                        const optionsBySpace = onSpaceDropdownSelection(spaceDropdown, value);
                        if (value.Space) {
                            resetFilterMoveForm('Space', resetField, value, {
                                Category: optionsBySpace.Category,
                                'Asset Type': optionsBySpace['Asset Type'],
                                Asset: optionsBySpace.Asset,
                            });
                        }
                        const values = getValues();
                        onSpaceDropdownSelection(spaceDropdown, values);
                    }
                    break;
                case 'Category':
                    {
                        const optionsByCategory = onAssetCategoryDropdownSelection(assetCategoryDropdown, value);
                        if (value.Category) {
                            resetFilterMoveForm('Category', resetField, value, {
                                'Asset Type': optionsByCategory['Asset Type'],
                                Asset: optionsByCategory.Asset,
                            });
                        }
                        const values = getValues();
                        onAssetCategoryDropdownSelection(assetCategoryDropdown, values);
                    }
                    break;
                case 'Asset Type':
                    {
                        const optionsByAssetType = onAssetTypeDropdownSelection(assetTypeDropdown, value);
                        if (value['Asset Type']) {
                            resetFilterMoveForm('Asset Type', resetField, value, {
                                Asset: optionsByAssetType.Asset,
                            });
                        }
                        const values = getValues();
                        onAssetTypeDropdownSelection(assetTypeDropdown, values);
                    }
                    break;
            }
        });
        return () => subscription.unsubscribe();
    });
    const onSave = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e;
        const facilityFilters = Array.isArray(data.Facility)
            ? data.Facility
            : [data.Facility];
        const spaceIdentity = Array.isArray(data.Space)
            ? data.Space
            : [data.Space];
        const assetIdentity = Array.isArray(data.Asset)
            ? data.Asset
            : [data.Asset];
        const moveLocationData = {
            facilityIdentity: (_c = facilityFilters[0]) === null || _c === void 0 ? void 0 : _c.value,
            spaceIdentity: (_d = spaceIdentity[0]) === null || _d === void 0 ? void 0 : _d.value,
            assetIdentity: (_e = assetIdentity[0]) === null || _e === void 0 ? void 0 : _e.value,
            photoIdentities: selectedPhotos.map((photo) => photo.photoIdentity),
        };
        try {
            yield movePhotosLocation(moveLocationData);
            yield getPhotos();
            toast({
                title: 'Photos moved',
                status: 'success',
                isClosable: true,
            });
        }
        catch (error) {
            toast({
                title: 'Failed to move photos; try again later.',
                status: 'error',
                isClosable: true,
            });
        }
    });
    return (_jsx(Form.Modal, Object.assign({ disclosure: { onClose, isOpen, onOpen: open }, onApply: handleSubmit(onSave), onClear: onClose, labels: {
            title: 'Move Photo(s)',
            cancel: 'Cancel',
            apply: 'Move',
        }, applyDisabled: !canSave }, { children: _jsxs(Form.Column, { children: [_jsx(FormSelector, { isMulti: false, form: {
                        name: 'Facility',
                        control,
                    }, selectFrom: facilityDropdown !== null && facilityDropdown !== void 0 ? facilityDropdown : [] }), _jsx(FormSelector, { isMulti: false, form: {
                        name: 'Space',
                        control,
                    }, selectFrom: spaceDropdown !== null && spaceDropdown !== void 0 ? spaceDropdown : [] }), _jsx(FormSelector, { isMulti: false, form: {
                        name: 'Category',
                        control,
                    }, selectFrom: (_a = getListOfUniqueValues(assetCategoryDropdown, 'category')) !== null && _a !== void 0 ? _a : [] }), _jsx(FormSelector, { isMulti: false, form: {
                        name: 'Asset Type',
                        control,
                    }, selectFrom: (_b = getListOfUniqueValues(assetTypeDropdown, 'assetType')) !== null && _b !== void 0 ? _b : [] }), _jsx(FormSelector, { isMulti: false, form: {
                        name: 'Asset',
                        control,
                    }, selectFrom: assetDropdown !== null && assetDropdown !== void 0 ? assetDropdown : [] })] }) })));
};
