var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext, } from 'react-hook-form';
import { useFacilityContext } from '../../../../../../contexts/Facilities/FacilityPageContext';
import { useTemplateContext } from '../../../../../../contexts/Template/TemplateContext';
import { convertAssetToAssetForm } from '../../../../../../conversions/AssetConversions';
import { PageModes, } from '../../../../../../objects/FCAInterfaces';
import { FacilityAssetSelector } from './FacilityAssetSelector';
import { StandardAsset } from './StandardAsset';
export const StandardAssets = ({ spaceIdentity, spaceName, standardAssetTemplates, canEdit, isEditing, standardAssets, orgIdentity, allFacilityAssetsList, deleteAssets, trackDeletedAsset, }) => {
    const { getAssetTemplateVersionByClass } = useTemplateContext();
    const { pageMode, setPageMode } = useFacilityContext();
    const { control, getValues, trigger, reset } = useFormContext();
    const { fields, append } = useFieldArray({
        name: 'standardAssets',
        control: control,
    });
    const [expandedAssetIndex, setExpandedAssetIndex] = useState(undefined);
    const [expandedClassIndex, setExpandedClassIndex] = useState(undefined);
    // reordering standardAssets to match the index order of fields
    const orderedAssets = [];
    fields.forEach((field) => {
        const standardAsset = standardAssets === null || standardAssets === void 0 ? void 0 : standardAssets.find((asset) => asset.name === field.name);
        orderedAssets.push(standardAsset);
    });
    useEffect(() => {
        setExpandedClassIndex(undefined);
        setExpandedAssetIndex(undefined);
    }, [spaceIdentity]);
    const addNewAsset = (e, classIndex, assetClassTemplate, assetClassFields) => {
        var _a;
        e.stopPropagation();
        const newAssetForm = convertAssetToAssetForm(undefined, assetClassTemplate.assetClass, assetClassTemplate);
        const hasPercentageQuestion = (_a = assetClassTemplate.survey) === null || _a === void 0 ? void 0 : _a.some((question) => question.name === 'AreaPercentage');
        const updatedAssetForm = !assetClassFields.length && hasPercentageQuestion
            ? updatedStandardAssets(newAssetForm)
            : newAssetForm;
        append(updatedAssetForm);
        setExpandedClassIndex(classIndex);
        setExpandedAssetIndex(assetClassFields.length);
        if (pageMode !== PageModes.ADD_ASSET) {
            setPageMode(PageModes.EDIT);
        }
    };
    const updatedStandardAssets = (newAssetForm) => {
        return Object.assign(Object.assign({}, newAssetForm), { questions: newAssetForm.questions.map((q) => q.name === 'AreaPercentage' ? Object.assign(Object.assign({}, q), { value: '100' }) : q) });
    };
    /**
     * Delete a standard asset
     * If the user is editing, the standard asset will be removed from the form and tracked for save
     * If the user is viewing, the standard asset will be deleted by the platform
     * @param fieldIndex react-hook-form useFieldArray index of standard asset in form
     * @param isEditing is the user editing a space/standard asset or viewing
     */
    const removeStandardAsset = (fieldIndex, isEditing, assetClassTemplate) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // We need to use getValues to ensure we are retrieving the latest values from the form
        // useFieldArray does not retrigger on internal field updates
        const currentFields = getValues('standardAssets');
        const assetToDelete = currentFields[fieldIndex];
        const hasPercentageQuestion = (_a = assetClassTemplate.survey) === null || _a === void 0 ? void 0 : _a.some((question) => question.name === 'AreaPercentage');
        const assetToReconcileIndex = currentFields.findIndex((field, index) => field.assetClass === assetClassTemplate.assetClass &&
            index !== fieldIndex //ignore the asset that will be deleted
        );
        const hasAssetToReconcile = hasPercentageQuestion && assetToReconcileIndex >= 0;
        let assetToReconcile = undefined;
        if (hasPercentageQuestion) {
            if (hasAssetToReconcile) {
                assetToReconcile = Object.assign(Object.assign({}, currentFields[assetToReconcileIndex]), { questions: currentFields[assetToReconcileIndex].questions.map((q) => q.name === 'AreaPercentage' ? Object.assign(Object.assign({}, q), { value: '100' }) : q) });
                currentFields.splice(assetToReconcileIndex, 1, assetToReconcile);
            }
        }
        if (assetToDelete.identity) {
            if (!isEditing) {
                const reconcileObj = hasAssetToReconcile
                    ? {
                        spaceIdentity,
                        assetsToReconcile: [
                            assetToReconcile,
                        ],
                        getAssetTemplateVersionByClass,
                    }
                    : undefined;
                return deleteAssets([assetToDelete.identity], reconcileObj);
            }
            else {
                // only track if the asset exists and needs to be deleted on save
                trackDeletedAsset(assetToDelete);
            }
        }
        currentFields.splice(fieldIndex, 1);
        // reset so we can update the form values for proper save state
        const spaceForm = getValues();
        reset(Object.assign(Object.assign({}, spaceForm), { standardAssets: [...currentFields] }));
        return Promise.resolve();
    });
    return (_jsxs(Box, Object.assign({ "data-testid": 'sidebar-space-asset-form' }, { children: [_jsx(Accordion, Object.assign({ allowToggle: true, index: expandedClassIndex !== undefined ? [expandedClassIndex] : [], onChange: (index) => {
                    setExpandedClassIndex(Array.isArray(index) ? index[0] : index);
                    setExpandedAssetIndex(undefined);
                    trigger('standardAssets');
                } }, { children: standardAssetTemplates.map((assetClassTemplate, classIndex) => {
                    return (_jsx(React.Fragment, { children: _jsx(StandardAsset, { assetClassTemplate: assetClassTemplate, addNewAsset: addNewAsset, removeAsset: removeStandardAsset, expandedAssetIndex: expandedAssetIndex, setExpandedAssetIndex: setExpandedAssetIndex, classIndex: classIndex, spaceIdentity: spaceIdentity, spaceName: spaceName, orgIdentity: orgIdentity, fields: fields, standardAssets: orderedAssets, assetTemplates: standardAssetTemplates, canEdit: canEdit, isEditing: isEditing, control: control, trigger: trigger, getValues: getValues }) }, `${assetClassTemplate.assetClass}-${classIndex}`));
                }) })), _jsx(Controller, { name: 'facilityAssignedAssets', control: control, render: ({ field: { value, onChange } }) => {
                    return (_jsx(FacilityAssetSelector, { allFacilityAssetsList: allFacilityAssetsList, facilityAssets: value, onChange: (assetIdentity) => {
                            const updatedAssets = value.includes(assetIdentity)
                                ? value.filter((identity) => identity !== assetIdentity)
                                : [...value, assetIdentity];
                            onChange(updatedAssets);
                            if (canEdit && !isEditing) {
                                setPageMode(PageModes.EDIT);
                            }
                        } }));
                } })] })));
};
