var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonGroup } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { toast } from '@frontend/domain/ToastContainer';
import { usePhotoSelector } from '../../contexts/Photo/PhotoSelectContext';
import { SelectionType } from '../../contexts/Photo/PhotoSelectProvider';
import { usePlatformPhotoApi } from '../../services/api/platformPhotoApiHook';
export const DownloadPhotoButton = ({ orgIdentity, }) => {
    const { startSelecting, stopSelecting, selectedPhotos, isMoving, isSelecting, } = usePhotoSelector();
    const { triggerDownload } = usePlatformPhotoApi();
    const photoDownloadMax = 42;
    const onConfirmDownloadButton = () => __awaiter(void 0, void 0, void 0, function* () {
        const photos = selectedPhotos.map((photo) => photo.photoIdentity);
        yield triggerDownload(orgIdentity, photos)
            .then(() => toast({
            title: 'Photo download started',
            description: 'You will receive an email shortly with a link to the downloaded photos',
            status: 'success',
            isClosable: true,
        }))
            .catch((err) => {
            console.error(err);
            toast({
                title: 'Photo download could not be started',
                description: err,
                status: 'error',
                isClosable: true,
            });
        });
        stopSelecting();
    });
    const onDownloadButton = () => {
        startSelecting(SelectionType.DOWNLOAD, photoDownloadMax);
    };
    return (_jsx(_Fragment, { children: isSelecting ? (!isMoving && (_jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ variant: variants.transparentRedBtn, onClick: stopSelecting }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: variants.fcaBlueBtn, onClick: onConfirmDownloadButton, isDisabled: selectedPhotos.length === 0, 
                    // TODO: but this styling in the theme for blueBtn variant
                    //       was not getting picked up in variant so had to define here
                    _hover: { bg: '' } }, { children: "Confirm Download" }))] }))) : (_jsx(Button, Object.assign({ variant: variants.fcaTransparentBlueBtn, onClick: onDownloadButton }, { children: "Download" }))) }));
};
