var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const baseUrl = '/photos';
const baseUrlPlatformAPI = '/Photo';
export default class PhotoService {
    constructor(api, platformApi) {
        this.api = api;
        this.platformApi = platformApi;
    }
    getPhoto(phid) {
        return __awaiter(this, void 0, void 0, function* () {
            // Todo: Move to service layer
            const resPhoto = yield this.api.get(`${baseUrl}/${phid}`);
            const photo = {
                id: resPhoto.id,
                photoIdentity: resPhoto.photoIdentity,
                name: resPhoto.name,
                url: resPhoto.url,
                favorite: resPhoto.favorite,
                comments: resPhoto.comments,
                createDate: resPhoto.createDate,
                tags: resPhoto.tags,
                date: resPhoto.date,
                space: resPhoto.space && {
                    name: resPhoto.space.spaceId,
                    identity: resPhoto.space.spaceIdentity,
                },
                asset: resPhoto.asset && {
                    name: resPhoto.asset.name,
                    identity: resPhoto.asset.assetIdentity,
                    assetClass: resPhoto.asset.assetType,
                },
                facility: resPhoto.facility && {
                    name: resPhoto.facility.name,
                    identity: resPhoto.facility.facilityIdentity,
                },
            };
            return photo;
        });
    }
    createPhotos(photos) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.post(`${baseUrl}/list`, photos);
        });
    }
    updatePhoto(phid, photo) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${phid}`, photo);
        });
    }
    addComment(phid, comment) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${phid}/comments`, comment);
        });
    }
    deletePhoto(pid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.delete(`${baseUrl}/${pid}`);
        });
    }
    triggerDownloadPhotos(requestBody) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`${baseUrlPlatformAPI}/download`, requestBody);
        });
    }
    // TODO: Move to generic service layer
    getPhotosByOrg(orgIdentity, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            let relations = {};
            if (filters) {
                if (filters.favorites) {
                    relations = Object.assign(Object.assign({}, relations), { 'relations[favorites]': filters.favorites });
                }
                if (filters.facilityIdentities) {
                    const identities = filters.facilityIdentities.map((facility) => facility && facility.value);
                    relations = Object.assign(Object.assign({}, relations), { 'relations[facilityIdentities]': identities });
                }
                if (filters.spaceIdentities) {
                    const identities = filters.spaceIdentities.map((space) => space && space.value);
                    relations = Object.assign(Object.assign({}, relations), { 'relations[spaceIdentities]': identities });
                }
                if (filters.spaceYears) {
                    const identities = filters.spaceYears.map((spaceYear) => spaceYear && spaceYear.value);
                    relations = Object.assign(Object.assign({}, relations), { 'relations[spaceYears]': identities });
                }
                if (filters.assetCategories) {
                    const identities = filters.assetCategories.map((assetCategory) => assetCategory && assetCategory.value);
                    relations = Object.assign(Object.assign({}, relations), { 'relations[assetCategories]': identities });
                }
                if (filters.assetTypes) {
                    const identities = filters.assetTypes.map((assetType) => assetType && assetType.value);
                    relations = Object.assign(Object.assign({}, relations), { 'relations[assetTypes]': identities });
                }
                if (filters.assetYears) {
                    const identities = filters.assetYears.map((assetYear) => assetYear && assetYear.value);
                    relations = Object.assign(Object.assign({}, relations), { 'relations[assetYears]': identities });
                }
                if (filters.assetIdentities) {
                    const identities = filters.assetIdentities.map((assetIdentity) => assetIdentity && assetIdentity.value);
                    relations = Object.assign(Object.assign({}, relations), { 'relations[assetIdentities]': identities });
                }
                if (filters.conditions) {
                    const identities = filters.conditions.map((condition) => condition && condition.value);
                    relations = Object.assign(Object.assign({}, relations), { 'relations[conditions]': identities });
                }
                if (filters.tags) {
                    relations = Object.assign(Object.assign({}, relations), { 'relations[tags]': filters.tags });
                }
            }
            const res = yield this.api.getWithQueryParams(`${baseUrl}/${orgIdentity}/organizationPhotos`, {}, relations);
            const consolidatedPhoto = res.map((resPhoto) => {
                const photo = {
                    id: resPhoto.id,
                    photoIdentity: resPhoto.photoIdentity,
                    name: resPhoto.name,
                    url: resPhoto.url,
                    favorite: resPhoto.favorite,
                    comments: resPhoto.comments,
                    createDate: resPhoto.createDate,
                    tags: resPhoto.tags,
                    date: resPhoto.date,
                    space: resPhoto.space && {
                        name: resPhoto.space.spaceId,
                        identity: resPhoto.space.spaceIdentity,
                    },
                    asset: resPhoto.asset && {
                        name: resPhoto.asset.name,
                        identity: resPhoto.asset.assetIdentity,
                        assetClass: resPhoto.asset.assetType,
                    },
                    facility: resPhoto.facility && {
                        name: resPhoto.facility.name,
                        identity: resPhoto.facility.facilityIdentity,
                    },
                };
                return photo;
            });
            return consolidatedPhoto;
        });
    }
    addTagToPhoto(photoIdentity, tagIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${photoIdentity}/tags/${tagIdentity}`);
        });
    }
    deletePhotoTag(photoIdentity, tagIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.delete(`${baseUrl}/${photoIdentity}/tags/${tagIdentity}`);
        });
    }
    movePhotosLocation(locationData) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/move`, locationData);
        });
    }
}
