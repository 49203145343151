export const rgbFromNumber = (n) => {
    const r = (n * 123456789) % 256;
    const g = (n * 987654321) % 256;
    const b = (n * 543219876) % 256;
    return { r, g, b };
};
export const simpleStringHash = (s) => {
    return Math.abs(s.split('').reduce((hash, c) => {
        const charCode = `${c}`.charCodeAt(0);
        let newHash = (hash << 5) - hash + charCode;
        return (newHash |= 0);
    }, 0));
};
