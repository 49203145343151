import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
export const useAssetsService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const deleteAsset = (assetIdentity) => {
        return handlePlatformResponse(platformApi.asset.deleteApiV1AssetRemove(assetIdentity), {
            error: { label: 'Error occurred retrieving Asset List' },
            success: { enabled: true, label: 'Successfully deleted asset' },
        });
    };
    // const fetchAssetList = async (
    // 	assetClassTypes: AssetClassType[] = [],
    // 	facilityId?: number,
    // 	floorPlanId?: number,
    // 	spaceId?: number
    // ): Promise<IAssetSummary[] | undefined> => {
    // 	return handlePlatformResponse(
    // 		platformApi.asset.postApiV1AssetList({
    // 			assetClassTypes,
    // 			facilityId,
    // 			floorPlanId,
    // 			spaceId,
    // 		}),
    // 		{
    // 			error: { label: 'Error occurred retrieving Asset List' },
    // 			success: { enabled: false },
    // 		}
    // 	).then((res: AssetSummaryResponse[]) =>
    // 		res.map((r: AssetSummaryResponse) => r)
    // 	);
    // };
    // const loadAsset = async (
    // 	assetClassTypes: AssetClassType[],
    // 	facilityId?: number,
    // 	spaceId?: number,
    // 	assetId?: number
    // ): Promise<IAssetDetails | undefined> => {
    // 	return handlePlatformResponse(
    // 		platformApi.asset.postApiV1AssetLoad({
    // 			facilityId: facilityId,
    // 			assetClassTypes: [...assetClassTypes],
    // 		}),
    // 		{
    // 			error: { label: 'Error occurred retrieving Asset Details' },
    // 			success: { enabled: false },
    // 		}
    // 	).then((res: AssetDetailResponse[]) => {
    // 		// TODO: not all fields are being mapped, only ones set on create
    // 		// return convertAssetDetailResponseToIAssetDetail(res);
    // 		return res.map((r: AssetDetailResponse) => r);
    // 	});
    // };
    return {
        asset: {
            delete: deleteAsset,
        },
    };
};
