var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { usePhotoContext } from '@/contexts/Photo/PhotoContext';
import { toast } from '@frontend/domain/ToastContainer';
import { useRef, useState } from 'react';
import { toAssetString } from '../../../../../config/assetString';
import { AddPhotoModal } from './AddPhotoModal';
export var PhotoType;
(function (PhotoType) {
    PhotoType[PhotoType["SPACE"] = 0] = "SPACE";
    PhotoType[PhotoType["PINNED_ASSET"] = 1] = "PINNED_ASSET";
    PhotoType[PhotoType["STANDARD_ASSET"] = 2] = "STANDARD_ASSET";
})(PhotoType || (PhotoType = {}));
export const SetUpPhotoModal = ({ photoType, canEdit, isOpen, onClose, spaceIdentity, assetIdentity, spaceName, assetName, }) => {
    const fileListRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedDate, setSelectedDate] = useState(undefined);
    const { submitSpacePhoto, submitAssetPhoto } = usePhotoContext();
    const getAddedToName = (photoType) => {
        switch (photoType) {
            case PhotoType.SPACE:
                return spaceName ? spaceName : '';
            case PhotoType.STANDARD_ASSET:
                return spaceName && assetName
                    ? `${toAssetString(assetName)} for ${spaceName}`
                    : '';
            case PhotoType.PINNED_ASSET:
                return assetName ? assetName : '';
        }
    };
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (photoType !== PhotoType.SPACE) {
            if (!assetIdentity) {
                toast({
                    title: 'Unable to upload photo to Asset',
                    isClosable: true,
                });
            }
            else {
                yield submitAssetPhoto(spaceIdentity, assetIdentity, {
                    fileList: fileList,
                    name: getAddedToName(photoType),
                    selectedTags: selectedTags,
                    selectedDate: selectedDate,
                });
            }
        }
        else {
            yield submitSpacePhoto(spaceIdentity, {
                fileList: fileList,
                name: getAddedToName(photoType),
                selectedTags: selectedTags,
                selectedDate: selectedDate,
            });
        }
        onClose();
    });
    return (_jsx(AddPhotoModal, { addingTo: getAddedToName(photoType), canEdit: canEdit, isOpen: isOpen, onClose: onClose, onSubmit: onSubmit, photoRef: fileListRef, setPhotos: setFileList, selectedTags: selectedTags, setSelectedTags: setSelectedTags, setSelectedDate: setSelectedDate }));
};
