var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, AlertIcon, AlertTitle, Button, CloseButton, } from '@chakra-ui/react';
import { toast } from '@frontend/domain/ToastContainer';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { createPhotos } from '../services/api/photoAPI';
import { uploadFileToBlob } from '../services/fileUpload';
import { toCleanFileName, toContainerPath, toCurrentYYYYMMDDHHMMSSMSMS, } from '../utils/photoFileUtils';
import { useImageHandler } from './imageHandler';
export const usePhotoServiceHook = () => {
    const { compressImage } = useImageHandler();
    const uploadPhotoToBlobStorage = (photoFile) => __awaiter(void 0, void 0, void 0, function* () {
        // store raw photo first
        const fileName = photoFile.appendString + toCleanFileName(photoFile.file.name);
        const rawContainerPath = toContainerPath(fileName);
        // we aren't awaiting this, because the client does not want the slow upload from the raw photos
        uploadFileToBlob(photoFile.file, rawContainerPath);
        const compressedFile = yield compressImage(photoFile.file);
        // only store thumbnail if it could be compressed
        if (compressedFile) {
            const compressedContainerPath = toContainerPath(fileName, 'compressed');
            yield uploadFileToBlob(compressedFile, compressedContainerPath);
        }
        else {
            throw new Error(fileName);
        }
    });
    const downloadPhotosToZip = (name, photos) => __awaiter(void 0, void 0, void 0, function* () {
        const zip = new JSZip();
        const photosFolder = zip.folder(name);
        if (photos) {
            for (const photo of photos) {
                photosFolder === null || photosFolder === void 0 ? void 0 : photosFolder.file(photo.name, photo);
            }
            const zipFile = yield zip.generateAsync({ type: 'blob' });
            saveAs(zipFile, `${name}.zip`);
        }
    });
    const renderFailedUploadToast = (name, failedFiles, allFiles) => {
        toast({
            status: 'error',
            isClosable: true,
            duration: null,
            render: ({ onClose }) => {
                return (_jsxs(Alert, Object.assign({ status: 'error', borderRadius: 8, variant: 'solid', alignItems: 'flex-start' }, { children: [_jsx(AlertIcon, {}), _jsxs(AlertTitle, Object.assign({ flex: 2 }, { children: [name, " failed to upload ", failedFiles.length, "/", allFiles.length, " photos."] })), _jsx(Button, Object.assign({ onClick: () => {
                                downloadPhotosToZip(name, failedFiles);
                                onClose();
                            }, variant: 'outline', color: 'base.white', flex: 1, _hover: { color: 'base.black', bg: 'base.white' } }, { children: "Download photos" })), _jsx(CloseButton, { alignSelf: 'flex-start', position: 'relative', right: -1, top: -1, size: 'sm', onClick: onClose })] })));
            },
        });
    };
    const uploadPhotos = (createPhotoIdentities, { fileList, name, selectedDate, selectedTags }) => __awaiter(void 0, void 0, void 0, function* () {
        const [photoFiles, failedFiles] = yield multiPhotoBlobStorageUpload(fileList);
        if (fileList) {
            try {
                yield createPhotos(photoFiles, createPhotoIdentities, selectedTags, selectedDate);
                toast({
                    title: `${name} updated`,
                    status: 'success',
                    isClosable: true,
                });
                // If any of the photos failed to upload to blob, then we would not have been able to create those photos
                // Thus, we need to notify the user
                if (failedFiles.length) {
                    renderFailedUploadToast(name, failedFiles, fileList);
                }
            }
            catch (error) {
                // If createPhotos failed, all of the photos have failed to have been uploaded
                console.error('Failed to add photos. ', error);
                renderFailedUploadToast(name, fileList, fileList);
            }
        }
    });
    // When migrating to platform; will need to consider the implementation of photo by photo toast notifications.
    // These toast notifications were to keep user updated on progress of a large upload, and so they would know which specific files failed, so they could retake them
    /**
     This sets up multiple photos to be uploaded to blob storage and notifies user with toasts as each photo is successfully uploaded to blob storage.
     */
    const multiPhotoBlobStorageUpload = (fileList, noAppend) => __awaiter(void 0, void 0, void 0, function* () {
        toast({
            title: 'Upload beginning; feel free to continue using the FCA tool.',
            status: 'success',
            isClosable: true,
        });
        const photoArray = fileList || [];
        const failedFiles = [];
        const photoFiles = photoArray.map((photo) => {
            const dateTimeString = noAppend
                ? ''
                : toCurrentYYYYMMDDHHMMSSMSMS();
            return { appendString: dateTimeString, file: photo };
        });
        const uploadedFiles = [];
        for (const [i, photo] of photoFiles.entries()) {
            try {
                yield uploadPhotoToBlobStorage(photo);
                uploadedFiles.push(photo);
                toast({
                    title: `Photo ${i + 1} of ${photoFiles.length} uploaded.`,
                    status: 'success',
                    isClosable: true,
                });
            }
            catch ({ name, message }) {
                console.error('Error uploading photos:', message);
                toast({
                    title: `Error uploading photo ${i + 1} of ${photoFiles.length}; please try again.`,
                    status: 'error',
                    duration: null,
                    isClosable: true,
                });
                failedFiles.push(photo.file);
            }
        }
        return [uploadedFiles, failedFiles];
    });
    return {
        uploadPhotoToBlobStorage,
        uploadPhotos,
    };
};
