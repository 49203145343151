import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { PhotoProvider } from '@/contexts/Photo/PhotoProvider';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { toast } from '@frontend/domain/ToastContainer';
import { useEffect, useState } from 'react';
import { PageLayout } from '../../../components/layouts/PageLayout';
import { GalleryProvider } from '../../../contexts/Photo/GalleryProvider';
import { PhotoSelectProvider } from '../../../contexts/Photo/PhotoSelectProvider';
import { getFacilitiesSpacesAssetsByOrg } from '../../../services/api/facilityAPI';
import { Gallery } from './Gallery';
export const GalleryPage = () => {
    const { selectedOrganizationIdentity: orgIdentity } = useOrganizationSummaries();
    const [facilities, setFacilities] = useState([]);
    const [spaces, setSpaces] = useState([]);
    const [assets, setAssets] = useState([]);
    useEffect(() => {
        if (orgIdentity) {
            getFacilitiesSpacesAssetsByOrg(orgIdentity)
                .then((fetchedData) => {
                const facilitiesArray = fetchedData.map((facility) => {
                    return {
                        identity: facility.identity,
                        name: facility.name,
                    };
                });
                const spacesAssetsArray = fetchedData.flatMap((facility) => {
                    if (facility.spaces) {
                        return facility.spaces;
                    }
                    else
                        return [];
                });
                const spacesArray = spacesAssetsArray.map((space) => {
                    return {
                        identity: space.identity,
                        facilityIdentity: space.facilityIdentity,
                        constrYear: space.constrYear,
                        name: space.name,
                    };
                });
                const assetsArray = spacesAssetsArray.flatMap((space) => {
                    if (space.assets) {
                        return space.assets;
                    }
                    else
                        return [];
                });
                setFacilities(facilitiesArray);
                setSpaces(spacesArray);
                setAssets(assetsArray);
            })
                .catch((error) => {
                console.error(error);
                toast({
                    title: 'Something went wrong with fetching facilities; try again later.',
                    status: 'error',
                    isClosable: true,
                });
            });
        }
    }, [orgIdentity]);
    return (_jsx(PageLayout, Object.assign({ title: 'myGallery' }, { children: _jsx(_Fragment, { children: orgIdentity && (_jsx(GalleryProvider, Object.assign({ allFacilities: facilities, allSpaces: spaces, allAssets: assets }, { children: _jsx(PhotoProvider, Object.assign({ orgIdentity: orgIdentity }, { children: _jsx(PhotoSelectProvider, Object.assign({ orgIdentity: orgIdentity }, { children: _jsx(Gallery, { orgIdentity: orgIdentity }) })) })) }))) }) })));
};
