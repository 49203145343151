import { jsx as _jsx } from "react/jsx-runtime";
import { NavBar } from '@frontend/design-system/components/NavBar/NavBar';
import { OptimizeEnabledIcon, OrganizeEnabledIcon, OrgSettingsBoldIcon, OrgSettingsIcon, PrioritizeEnabledIcon, SupportBoldIcon, SupportIcon, SystemBoldIcon, SystemIcon, } from '@frontend/design-system/theme/icons';
import { useOrgAccess } from '@frontend/domain/contexts/Authorization/OrgAccess';
import { isLocalAdminPlus } from '@frontend/domain/contexts/Authorization/Roles';
import { useAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { useManageOrganizationPermissions, useNavigationPermissions, } from '@frontend/domain/contexts/Authorization/UserPermission';
import { consolidateName } from '@frontend/domain/conversion/NameConversions';
import { IAppNames } from '@frontend/domain/models/App';
import React from 'react';
import { useLocation } from 'react-router-dom';
import LogoFull from '../../images/LogoFull.png';
import LogoSmall from '../../images/LogoSmall.png';
import { AppRoutes } from '../Routing/RouteConstants';
export var NavLinkName;
(function (NavLinkName) {
    NavLinkName["ORGANIZE"] = "Organize";
    NavLinkName["PRIORITIZE"] = "Prioritize";
    NavLinkName["OPTIMIZE"] = "Optimize";
    NavLinkName["SYSTEM_ADMIN"] = "System Admin";
    NavLinkName["ORGANIZATION_SETTINGS"] = "Organization Settings";
    NavLinkName["SUPPORT"] = "Support";
})(NavLinkName || (NavLinkName = {}));
export const ExpandableSidebar = ({ children, user, orgIdentity, reports, externalBasePaths, app, }) => {
    var _a;
    const { canSeeAdminPage } = useNavigationPermissions();
    const { hasAccess: hasReportsAccess } = useAppAccess(IAppNames.REPORTS);
    const { canViewReports } = useManageOrganizationPermissions();
    const hasFCAAccess = useOrgAccess(IAppNames.FCA);
    const hasPlannerAccess = useOrgAccess(IAppNames.PLANNER);
    const hasPIQAccess = useOrgAccess(IAppNames.PIQ);
    const location = useLocation();
    const currentURL = location.pathname;
    const isSublinkCurrent = (sublinkURL) => {
        return currentURL === sublinkURL;
    };
    const mapSublinks = (sublinks) => {
        return sublinks.map((sublink) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, sublink), { isCurrentLink: isSublinkCurrent((_b = (_a = sublink.link) === null || _a === void 0 ? void 0 : _a.href) !== null && _b !== void 0 ? _b : '') }));
        });
    };
    const spreadReports = (orgIdentity, reportList) => {
        const filteredReportsList = reportList === null || reportList === void 0 ? void 0 : reportList.filter((report) => canViewReports || report.showCustomer === true);
        return filteredReportsList &&
            filteredReportsList.length > 0 &&
            hasReportsAccess
            ? [
                {
                    title: 'Reports',
                    subGroups: filteredReportsList.map((report) => ({
                        title: report.name,
                        link: {
                            href: AppRoutes.reports(orgIdentity, report.id, app === IAppNames.REPORTS ? undefined : externalBasePaths.base),
                            external: app !== IAppNames.REPORTS,
                        },
                    })),
                },
            ]
            : [];
    };
    const appToExternalBasePath = {
        [IAppNames.FCA]: externalBasePaths.organize,
        [IAppNames.PLANNER]: externalBasePaths.prioritize,
        [IAppNames.PIQ]: externalBasePaths.opportunities,
        [IAppNames.REPORTS]: externalBasePaths.base,
    };
    const generateLink = (orgIdentity, generateHref, linkApp) => {
        const isExternalRoute = app !== linkApp;
        if (isExternalRoute && orgIdentity) {
            return {
                href: generateHref(orgIdentity, appToExternalBasePath[linkApp]),
                external: isExternalRoute,
            };
        }
        else {
            return {
                href: generateHref(orgIdentity),
            };
        }
    };
    return (_jsx(NavBar, Object.assign({ squishContent: true, user: {
            name: consolidateName({
                firstName: user === null || user === void 0 ? void 0 : user.firstName,
                lastName: user === null || user === void 0 ? void 0 : user.lastName,
            }),
            systemRole: (_a = user === null || user === void 0 ? void 0 : user.systemRole) !== null && _a !== void 0 ? _a : '',
        }, assets: {
            logoSmallSrc: LogoSmall,
            logoLargeSrc: LogoFull,
        }, links: {
            home: {
                href: AppRoutes.landingPage(orgIdentity, externalBasePaths.base),
                external: true,
            },
            profile: {
                href: AppRoutes.profile(externalBasePaths.base),
                external: true,
            },
            logout: {
                href: AppRoutes.logout(),
            },
        }, linkGroups: [
            ...(orgIdentity
                ? [
                    {
                        title: NavLinkName.ORGANIZE,
                        icon: OrganizeEnabledIcon,
                        selectedIcon: OrganizeEnabledIcon,
                        subLinks: mapSublinks([
                            {
                                title: 'myFacilities',
                                link: generateLink(orgIdentity, AppRoutes.myFacilities, IAppNames.FCA),
                            },
                            ...(hasFCAAccess
                                ? [
                                    {
                                        title: 'myGallery',
                                        link: generateLink(orgIdentity, AppRoutes.myGallery, IAppNames.FCA),
                                    },
                                ]
                                : []),
                            ...spreadReports(orgIdentity, reports === null || reports === void 0 ? void 0 : reports.organize),
                        ]),
                    },
                    {
                        title: NavLinkName.PRIORITIZE,
                        icon: PrioritizeEnabledIcon,
                        selectedIcon: PrioritizeEnabledIcon,
                        subLinks: [
                            ...(hasPlannerAccess
                                ? [
                                    {
                                        title: 'myProjects',
                                        link: generateLink(orgIdentity, AppRoutes.myProjects, IAppNames.PLANNER),
                                    },
                                    {
                                        title: 'myScenarios',
                                        link: {
                                            href: AppRoutes.myScenarios(orgIdentity, undefined, app === IAppNames.PLANNER
                                                ? undefined
                                                : externalBasePaths.prioritize),
                                            external: app !== IAppNames.PLANNER,
                                        },
                                    },
                                ]
                                : []),
                            ...(hasFCAAccess
                                ? [
                                    {
                                        title: 'myAssets',
                                        link: generateLink(orgIdentity, AppRoutes.myAssets, IAppNames.PLANNER),
                                    },
                                ]
                                : []),
                            ...(hasPIQAccess
                                ? [
                                    {
                                        title: 'myOpportunities',
                                        link: generateLink(orgIdentity, AppRoutes.myOpportunities, IAppNames.PIQ),
                                    },
                                ]
                                : []),
                            ...spreadReports(orgIdentity, reports === null || reports === void 0 ? void 0 : reports.prioritize),
                        ],
                    },
                    {
                        title: NavLinkName.OPTIMIZE,
                        icon: OptimizeEnabledIcon,
                        selectedIcon: OptimizeEnabledIcon,
                        subLinks: [...spreadReports(orgIdentity, reports === null || reports === void 0 ? void 0 : reports.optimize)],
                        divideAfter: true,
                    },
                    ...(canSeeAdminPage
                        ? [
                            {
                                title: NavLinkName.SYSTEM_ADMIN,
                                icon: SystemIcon,
                                selectedIcon: SystemBoldIcon,
                                link: {
                                    href: app === IAppNames.REPORTS
                                        ? AppRoutes.systemAdmin()
                                        : AppRoutes.systemAdmin(appToExternalBasePath[IAppNames.REPORTS]),
                                    external: app !== IAppNames.REPORTS,
                                },
                            },
                        ]
                        : []),
                    ...(orgIdentity && isLocalAdminPlus(user, orgIdentity)
                        ? [
                            {
                                title: NavLinkName.ORGANIZATION_SETTINGS,
                                icon: OrgSettingsIcon,
                                selectedIcon: OrgSettingsBoldIcon,
                                link: generateLink(orgIdentity, AppRoutes.organizationSettings, IAppNames.REPORTS),
                            },
                        ]
                        : []),
                ]
                : []),
            {
                title: NavLinkName.SUPPORT,
                icon: SupportIcon,
                selectedIcon: SupportBoldIcon,
                link: {
                    href: AppRoutes.support(),
                },
            },
        ] }, { children: children })));
};
